import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {whites} from "../../styles/globalStyleVars";
import Button from "../Button";
import CloseButton from "../CloseButton";
import SimpleBar from "simplebar-react";
import {useForm, FieldErrors} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import reactHtmlParser from "react-html-parser";
import {postForm} from "../../api/redux/career";
import Select, {components} from "react-select";
import text from "./Text";

const MyComponent = ({data}) => {

    const store = useSelector(store => store.careerReducer)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [offset, setOffset] = useState(0)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft + 15)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.container').offsetLeft + 15)
        })
    }, [])


    // dropdown style
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? '#2B3382' : '#FFF',
            margin: 0,
            cursor: 'pointer',
            textTransform: 'capitalize',
            transition: '.3s ease',
            ":hover": {
                backgroundColor: "#2B3382",
                color: '#FFF'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,

        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer'
        }),

    };

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.732" height="7.003"
                             viewBox="0 0 11.732 7.003">
                            <g id="Group_24047" data-name="Group 24047" transform="translate(18853.867 -10435.998)">
                                <g id="Group_24046" data-name="Group 24046"
                                   transform="translate(-18852.863 10442.258) rotate(-93)">
                                    <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none"
                                          stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_12381" data-name="Line 12381" y1="5" x2="5"
                                          transform="translate(0 5)" fill="none" stroke="#08082b" stroke-linecap="round"
                                          stroke-width="1"/>
                                </g>
                            </g>
                        </svg>

                        :

                        <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
                             viewBox="0 0 11.414 6.414">
                            <g id="Group_22451" data-name="Group 22451" transform="translate(10.707 0.707) rotate(90)">
                                <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none" stroke="#08082b"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_12381" data-name="Line 12381" y1="5" x2="5" transform="translate(0 5)"
                                      fill="none" stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>
                    }

                </components.DropdownIndicator>
            )
        );
    };


    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset, watch} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    const apiEndPoint = apiEndPoints.POST_FORM


    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append('form_id', 'career-form');
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('address', e?.address);
        formData.append('phone', e?.number);
        formData.append('message', e?.message);
        formData.append('file', e?.cv?.[0]);
        dispatch(postForm([apiEndPoint, formData]))
    }


    useEffect(() => {
        if (!store?.formLoading && isSubmitSuccessful) {
            success('CV Submitted')
            reset()
        }
    }, [isSubmitSuccessful, store])


    // career jobs
    const jobs = data && data
    const [selectData, setSelectData] = useState(null)
    const handleClick = (e) => {
        setSelectData(e)
        handleShow()
    }

    const filterModalData = jobs?.find(f => f?.data?.id == selectData)

    // options
    const jobList = jobs?.map(i => (
        {value: i?.data?.title, label: i?.data?.title}
    ))
    jobList && jobList.push({value: "Others", label: "Others"})


    const [selectJob, setSelectJob] = useState('')
    const [showOthers, setShowOthers] = useState(false)
    const handleJob = (e) => {
        setSelectJob(e)
        if (e === "Others") {
            setShowOthers(true)
        } else {
            setShowOthers(false)
        }
    }


    return (
        <StyledComponent className={'job-list'}>
            <Container fluid>
                <Row>
                    <Col md={6} sm={12} className={'job-list__items'} style={{paddingLeft: offset + 'px'}}>
                        <Title margin={'0 0 80px 0'} color={'#F5F5F5'} text={'Job Openings'}/>
                        <ul>
                            {jobs?.map(i => (
                                <li className={selectData == i?.data?.id ? 'active' : ''}
                                    onClick={() => handleClick(i?.data?.id)} key={i?.data?.id}>{i?.data?.title}</li>
                            ))}
                        </ul>
                    </Col>

                    <Col sm={12} md={6} className={'job-list__form'} style={{paddingRight: offset + 'px'}}>
                        {/*<p>{reactHtmlParser(filterModalData?.data?.overview)}</p>*/}
                        {/*<div onClick={handleShow}>*/}
                        {/*    <Button margin={'30px 0 0 0'} text={'View Details'}/>*/}
                        {/*</div>*/}
                        <h4>Submit Your CV Today</h4>
                        <Form>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('name', {
                                    required: {
                                        value: true,
                                        message: "Please enter your full name",
                                    }
                                })} type="text" placeholder="Name*"/>
                                <p className={'form-error'}>{errors.name?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('number', {
                                    required: {
                                        value: true,
                                        message: "Please enter your phone number",
                                    },
                                    maxLength: 14
                                })} type="number" placeholder="Phone*"/>
                                <p className={'form-error'}>{errors.number?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('email', {
                                    required: {
                                        value: true,
                                        message: "Please enter a valid email address"
                                    },
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Please enter a valid email address"
                                    }
                                })} type="email" placeholder="Email*"/>
                                <p className={'form-error'}>{errors.email?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('address')} type="text" placeholder="Address"/>

                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Select onChange={(e) => handleJob(e.value)} components={{DropdownIndicator}}
                                        styles={customStyles}
                                        classNamePrefix={'custom'} className='select-here files'
                                        placeholder={'Select Applying Position'}
                                        options={jobList}/>

                            </Form.Group>
                            {showOthers &&
                                <>
                                    <p style={{color: "rgba(245, 245, 245, 0.5)", marginBottom: "10px"}}>or</p>

                                    <Form.Group className={'from-group'}>
                                        <Form.Control {...register('position')} type="text"
                                                      placeholder="Applying Position"/>
                                    </Form.Group>
                                </>
                            }


                            <Form.Group className={'from-group'}>
                                <Form.Control type="text" placeholder="Cover Letter"/>
                            </Form.Group>

                            <div className="submit d-flex flex-wrap justify-content-between align-items-center">
                                <input {...register('cv')} type="file"/>
                                <p>{watch('cv')?.[0]?.name}</p>
                                <div onClick={handleSubmit(handleFormSubmit)}>
                                    <Button margin={'0px 0 0px 0'} submitButton
                                            text={'Submit'}/>
                                </div>

                                {/*<button type={'submit'} onSubmit={handleSubmit(handleFormSubmit)}>Submit</button>*/}
                            </div>


                        </Form>

                    </Col>
                </Row>
            </Container>

            <Modal className={'team-modal career-modal'} show={show} onHide={handleClose}>
                <div onClick={handleClose}>
                    <CloseButton/>
                </div>
                <SimpleBar style={{maxHeight: window.innerHeight - 150 + 'px'}}>
                    <div className="team-modal__content">

                        <h3>{filterModalData?.data?.title}</h3>
                        {reactHtmlParser(filterModalData?.data?.description)}
                        {/*<p>You will play a pivotal role in designing, planning, and overseeing the development of*/}
                        {/*    various real estate projects, ensuring their functionality, aesthetic appeal, and adherence*/}
                        {/*    to regulatory standards. Your expertise will contribute to shaping the physical landscape of*/}
                        {/*    communities, neighborhoods, and commercial spaces, blending creativity with practicality to*/}
                        {/*    bring architectural visions to life.</p>*/}

                        {/*<h5>Job Summary</h5>*/}
                        {/*<ul>*/}
                        {/*    <li>Schedule and coordinate completion/Well Work activities</li>*/}
                        {/*    <li>This position is a designated as safety sensitive.</li>*/}
                        {/*</ul>*/}

                    </div>
                </SimpleBar>

            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .job-list__items {
    padding-top: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
    background-color: #08082B;

    ul {
      li {
        font-size: 20px;
        line-height: 28px;
        color: rgba(245, 245, 245, 0.5);
        cursor: pointer;
        transition: all .4s ease;
        padding-bottom: 30px;

        &:not(:nth-last-child(1)) {
          border-bottom: 1px solid rgba(245, 245, 245, 0.5);
          margin-bottom: 30px;
        }

        &.active {
          color: #f5f5f5;
        }

        &:hover {
          color: #f5f5f5;
        }
      }
    }
  }

  .job-list__form {
    background-color: #212158;
    padding-top: 80px;
    padding-bottom: 100px;
    padding-left: 80px;

    h4 {
      color: ${whites};
    }

    p {
      color: ${whites};
    }

    form {
      width: 100%;
      margin-top: 10px;
      padding-top: 40px;
      border-top: 1px solid ${whites};
    }

    .from-group {
      margin-bottom: 25px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }

    .form-control {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba(245, 245, 245, 0.5);
      padding-left: 0;
      height: 45px;
      color: ${whites};
      //color: rgba(245, 245, 245, 0.8);

      &::placeholder {
        font-size: 15px;
        color: rgba(245, 245, 245, 0.5);
      }

      &:focus {
        border-bottom: 1px solid ${whites};
      }
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: ${whites} !important;
      border-bottom: 1px solid #F5F5F5;

      &:hover {
        border-bottom: 1px solid #F5F5F5 !important;
      }

      &:focus {
        border-bottom: 1px solid #F5F5F5 !important;
      }
    }

    .submit {
      margin-top: 60px;
      position: relative;

      p {
        position: absolute;
        bottom: 15px;
        font-size: 12px;
        color: #9f9d9d;
        text-align: center;
      }

      .dc-btn {
        margin-bottom: 40px;
      }

      input {
        width: 170px;
        position: relative;
        height: 40px;
        padding-left: 5px;
        padding-top: 5px;
        cursor: pointer;
        overflow: hidden;
        margin-bottom: 40px;

        &:after {
          content: 'Upload CV';
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          bottom: 0px;
          background-color: rgb(33, 33, 88);
          border: 1px dashed rgb(255, 255, 255);
          left: 0;
          border-radius: 30px;
          cursor: pointer;
          font-size: 15px;
          line-height: 20px;
          color: ${whites};
          padding-left: 55px;
          display: flex;
          align-items: center;
        }

        &:before {
          content: '';
          position: absolute;
          background-image: url('/images/static/attach.svg');
          height: 30px;
          width: 30px;
          top: 13px;
          left: 28px;
          cursor: pointer;
          background-size: 15px;
          z-index: 2;
          background-repeat: no-repeat;
          margin: auto;
        }
      }
    }
  }


  //caret
  .custom__control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(245, 245, 245, 0.5) !important;
    border-radius: 0;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;
    //margin-bottom: 60px;
    svg line {
      stroke: ${whites};
    }

    .custom__input-container {
      padding-bottom: 12px !important;
    }

    .custom__placeholder, .custom__single-value {
      color: rgba(245, 245, 245, 0.5);
      font-size: 15px;
      line-height: 20px;
      text-transform: capitalize;
    }

    .custom__value-container {
      padding-left: 0;
    }

    &--is-focused {

    }
  }


  .custom__menu {
    z-index: 9;
  }

  .css-t3ipsp-control:hover {
    border-color: ${whites};
  }

  .custom__indicator-separator {
    display: none;
  }

  .custom__indicator {
    padding-right: 0;
  }

  @media (max-width: 767px) {
    .job-list__items {
      padding-right: 15px;
    }

    .job-list__form {
      padding-left: 15px;
    }
  }

`;

export default MyComponent;
