import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import Select, {components} from "react-select";
import {whites} from "../../styles/globalStyleVars";
import Button from "../Button";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/projects";

const MyComponent = ({id}) => {

    const store = useSelector(store => store)

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? '#2B3382' : '#]212158',
            margin: 0,
            cursor: 'pointer',
            ":hover": {
                backgroundColor: "#2B3382",
                color: '#FFF'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,

        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer'
        }),

    };

    const optionsOne = [
        {value: 'ongoing', label: 'One '},
        {value: 'completed', label: 'Two '},
        {value: 'upcoming', label: 'Three '},
    ];

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ? <img src={'/images/static/caret-white-v2.svg'} alt=""/> :
                        <img src={'/images/static/caret-white.svg'} alt=""/>}
                </components.DropdownIndicator>
            )
        );
    };


    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    const apiEndPoint = apiEndPoints.POST_FORM


    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append('form_id', 'project-form');
        formData.append('reason', e?.reason);
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.number);
        formData.append('message', e?.message);
        dispatch(postForm([apiEndPoint, formData]))
    }

    useEffect(() => {

        if (!store?.projectReducer?.formLoading && isSubmitSuccessful) {
            success('Thank you for your message')
            reset()
        }
    }, [isSubmitSuccessful, store])


    return (
        <StyledComponent id={id} className={'contact-form pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col sm={12} md={{span: 8, offset: 2}}>
                        <Title margin={'0 0 60px 0'} color={'#FFF'} center text={'Connect & Explore Opportunities'}/>

                        <Form>
                            <input type={'hidden'}/>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('name', {
                                    required: {
                                        value: true,
                                        message: "Please enter your full name",
                                    }

                                })} type="text" placeholder="Name*"/>
                                <p className={'form-error'}>{errors.name?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register("number", {
                                    required: {
                                        value: true,
                                        message: "Enter your valid phone number",
                                    },
                                    pattern: {
                                        value: /^01[0-9]{9}$/,
                                        message: "Enter a valid 11 digit phone number",
                                    },
                                })} type="number" placeholder="Number*"/>
                                <p className={'form-error'}>{errors.number?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('email', {
                                    required: {
                                        value: true,
                                        message: "Please enter a valid email address"
                                    },
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Please enter a valid email address"
                                    }
                                })} type="email" placeholder="Email*"/>
                                <p className={'form-error'}>{errors.email?.message}</p>
                            </Form.Group>
                            <textarea {...register('message')} placeholder={'Message'}/>

                            <div onClick={handleSubmit(handleFormSubmit)}>
                                <Button margin={'60px auto'} text={'Submit'}/>
                            </div>

                        </Form>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #212158;

    .from-group {
        margin-bottom: 25px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }

    .form-control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #F5F5F5;
        padding-left: 0;
        height: 45px;
        //margin-bottom: 25px;
        color: #F5F5F5;

        &::placeholder {
            font-size: 15px;
            color: ${whites};
        }
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: ${whites} !important;
        border-bottom: 1px solid #F5F5F5;

        &:hover {
            border-bottom: 1px solid #F5F5F5 !important;
        }

        &:focus {
            border-bottom: 1px solid #F5F5F5 !important;
        }
    }

    //caret

    .custom__control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid ${whites} !important;
        border-radius: 0;
        box-shadow: none;
        outline: none !important;
        cursor: pointer;

        .custom__placeholder, .custom__single-value {
            color: ${whites};
            font-size: 15px;
            line-height: 20px;
        }

        .custom__value-container {
            padding-left: 0;
        }

        &--is-focused {

        }
    }

    .css-t3ipsp-control:hover {
        border-color: ${whites};
    }

    .custom__indicator-separator {
        display: none;
    }

    .custom__indicator {
        padding-right: 0;
    }

    textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #F5F5F5;
        height: 80px;
        max-height: 80px;
        min-height: 80px;
        color: ${whites};
        box-shadow: none;
        outline: none;

        &::placeholder {
            font-size: 15px;
            color: ${whites};
        }
    }

`;

export default MyComponent;
