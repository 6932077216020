import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {ease, hover, text, whites} from "../styles/globalStyleVars";
import {CSSPlugin, gsap} from "gsap";

gsap.registerPlugin(CSSPlugin);

const MyComponent = () => {

    useEffect(() => {
        let tl = gsap.timeline()
        // tl.to('')
        const getLi = document.querySelectorAll('.floating-icon ul li:not(:nth-last-child(1))');
        document.querySelector('.mobile-click').addEventListener('click', function () {

            if (!document.body.classList.contains('action-btn-on')) {
                tl.to('.floating-icon ul li:not(:nth-last-child(1))', {
                    display: 'block',
                    opacity: 1,
                    y: 0
                })
                document.body.classList.add('action-btn-on')
            } else {
                tl.to('.floating-icon ul li:not(:nth-last-child(1))', {
                    y: '100%',
                    opacity: 0,
                    display: 'block',
                })
                document.body.classList.remove('action-btn-on')
            }

        });
    }, [])

    return (
        <StyledComponent className={'floating-icon'}>
            <ul>
                <li><a target={'_blank'} href="https://m.me/sheltechprivatelimited?text=Hello">
                    {/*<svg width="25.794" height="26" viewBox="0 0 25.794 26">*/}
                    {/*    <g id="Group_23335" data-name="Group 23335" transform="translate(-0.5 0)">*/}
                    {/*        <path id="Path_10743" data-name="Path 10743"*/}
                    {/*              d="M5.343,26a2.083,2.083,0,0,1-2.078-2.081V20.872A12.823,12.823,0,0,1,.617,11.147a12.9,12.9,0,0,1,25.6,3.225A12.881,12.881,0,0,1,8.337,24.747L6.274,25.778A2.083,2.083,0,0,1,5.343,26ZM11.92,1.938a11.129,11.129,0,0,0-9.477,9.45,11,11,0,0,0,2.452,8.568.922.922,0,0,1,.213.589v3.374a.236.236,0,0,0,.343.211L7.9,22.9a.919.919,0,0,1,.8-.01A11.05,11.05,0,1,0,11.92,1.938ZM11.8,1.024h0Zm0,0"*/}
                    {/*              transform="translate(0 0)" fill="#212158"/>*/}
                    {/*        <path id="Path_10744" data-name="Path 10744"*/}
                    {/*              d="M126.889,188.628l4.229-6.019a.59.59,0,0,1,.866-.179l2.833,2.124a.59.59,0,0,0,.658.034l3.45-2.438a.59.59,0,0,1,.831.77l-3.415,5.909a.59.59,0,0,1-.831.242l-3.782-2.269a.59.59,0,0,0-.568-.022l-3.495,2.668a.59.59,0,0,1-.776-.82Zm0,0"*/}
                    {/*              transform="translate(-119.894 -172.818)" fill="#212158"/>*/}
                    {/*    </g>*/}
                    {/*</svg>*/}

                    <img src="/images/static/messenger.svg" alt=""/>

                </a></li>

                <li><a target={'_blank'} href="https://wa.me/01713091700/?text=Hello">
                    <svg width="26" height="26" viewBox="0 0 26 26">
                        <g id="Group_23336" data-name="Group 23336" transform="translate(0 0)">
                            <path id="Path_10745" data-name="Path 10745"
                                  d="M18.468,14.97l-.01.081c-2.382-1.187-2.631-1.345-2.939-.884-.213.32-.835,1.044-1.023,1.259s-.378.227-.7.081a8.792,8.792,0,0,1-2.6-1.609,9.828,9.828,0,0,1-1.8-2.242c-.317-.548.347-.626.951-1.77a.6.6,0,0,0-.027-.568c-.081-.162-.728-1.755-1-2.39s-.528-.552-.728-.552a1.612,1.612,0,0,0-1.482.373C5.362,8.67,5.8,10.653,7.3,12.761c2.94,3.848,4.507,4.556,7.371,5.54a4.482,4.482,0,0,0,2.037.131,3.334,3.334,0,0,0,2.184-1.545,2.682,2.682,0,0,0,.195-1.544c-.08-.146-.292-.227-.617-.374Z"
                                  transform="translate(0.498 0.529)" fill="#212158"/>
                            <path id="Path_10746" data-name="Path 10746"
                                  d="M22.23,3.736C13.9-4.316.115,1.524.109,12.884a12.822,12.822,0,0,0,1.728,6.44L0,26l6.863-1.79A12.968,12.968,0,0,0,26,12.89a12.777,12.777,0,0,0-3.786-9.112Zm1.605,9.118A10.786,10.786,0,0,1,7.572,22.067l-.39-.232L3.12,22.892l1.089-3.949-.259-.406A10.744,10.744,0,0,1,20.684,5.282a10.6,10.6,0,0,1,3.151,7.572Z"
                                  transform="translate(0 0)" fill="#212158"/>
                        </g>
                    </svg>
                </a></li>

                <li className={'hotline'}><a href="tel:16550">
                    <img src="/images/static/hotline.svg" alt=""/>

                    {/*<span className="hotline__inner">*/}
                    {/*     <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18.24" viewBox="0 0 17 18.24">*/}
                    {/*    <g id="Group_24046" data-name="Group 24046" transform="translate(-0.871 0)">*/}
                    {/*        <path id="Path_10742" data-name="Path 10742"*/}
                    {/*              d="M15.968,12.7c-1.1-.942-2.22-1.513-3.309-.572l-.65.569c-.476.413-1.36,2.342-4.778-1.59S5.848,6.563,6.324,6.154l.653-.569c1.083-.943.674-2.13-.107-3.353L6.4,1.491C5.615.272,4.761-.529,3.675.413L3.089.925A5.506,5.506,0,0,0,.942,4.569c-.392,2.59.844,5.555,3.673,8.809s5.593,4.89,8.214,4.862a5.521,5.521,0,0,0,3.905-1.617l.589-.513c1.083-.941.41-1.9-.693-2.844Z"*/}
                    {/*              transform="translate(0 0)" fill="#08082b"></path>*/}
                    {/*    </g>*/}
                    {/*</svg>*/}
                    {/*    <p>16550</p>*/}
                    {/*</span>*/}
                    <img src="/images/static/hotline-v2.svg" className={'hotline__inner'} alt=""/>

                </a></li>

                <li className={'mobile-click'}><a>
                    {/*<svg width="29.164" height="27.486" viewBox="0 0 29.164 27.486">*/}
                    {/*    <path id="Path_10790" data-name="Path 10790"*/}
                    {/*          d="M17.525,40.39A1.825,1.825,0,0,0,15.7,38.565H13.465a1.825,1.825,0,1,0,0,3.65H15.7a1.825,1.825,0,0,0,1.825-1.825ZM3.837,35.684a7.9,7.9,0,0,0,1.044.015,5.181,5.181,0,0,0,5.083,4.179h.7a2.864,2.864,0,0,0,0,1.025h-.7a6.206,6.206,0,0,1-6.127-5.219Zm-.522-1.091A3.977,3.977,0,0,1,0,30.672V28.248a3.977,3.977,0,0,1,3.977-3.977h.265a10.374,10.374,0,0,1,20.682,0h.265a3.977,3.977,0,0,1,3.977,3.977v2.423a3.977,3.977,0,0,1-3.977,3.977H24.014a.881.881,0,0,1-.881-.881V25.1a8.551,8.551,0,1,0-17.1,0v8.664a.881.881,0,0,1-.484.787,5.378,5.378,0,0,1-2.232.039Z"*/}
                    {/*          transform="translate(0 -14.729)" fill="#08082b" fill-rule="evenodd"/>*/}
                    {/*</svg>*/}
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18.24" viewBox="0 0 17 18.24">
                        <g id="Group_24046" data-name="Group 24046" transform="translate(-0.871 0)">
                            <path id="Path_10742" data-name="Path 10742"
                                  d="M15.968,12.7c-1.1-.942-2.22-1.513-3.309-.572l-.65.569c-.476.413-1.36,2.342-4.778-1.59S5.848,6.563,6.324,6.154l.653-.569c1.083-.943.674-2.13-.107-3.353L6.4,1.491C5.615.272,4.761-.529,3.675.413L3.089.925A5.506,5.506,0,0,0,.942,4.569c-.392,2.59.844,5.555,3.673,8.809s5.593,4.89,8.214,4.862a5.521,5.521,0,0,0,3.905-1.617l.589-.513c1.083-.941.41-1.9-.693-2.844Z"
                                  transform="translate(0 0)" fill="#212158"/>
                        </g>
                    </svg>

                </a></li>
            </ul>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    position: fixed;
    right: 25px;
    bottom: 40px;
    z-index: 105;

    &:before {
        content: '';
        position: absolute;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: red;
        bottom: 0px;
        left: 0;
        animation: pulse 2s ease-in infinite;
    }

    @keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(180, 112, 79, .18), 0 0 0 0 rgba(255, 109, 74, .7);
            box-shadow: 0 0 0 0 rgba(222, 220, 219, .18), 0 0 0 0 rgba(255, 109, 74, .7);
        }
        40% {
            -webkit-box-shadow: 0 0 0 30px rgba(255, 110, 74, .466), 0 0 0 0 rgba(255, 109, 74, .7);
            box-shadow: 0 0 0 30px transparent, 0 0 0 0 rgba(255, 109, 74, .7);
        }
        80% {
            -webkit-box-shadow: 0 0 0 0 rgba(255, 110, 74, .555), 0 0 0 0 transparent;
            box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(204, 96, 96, .698), 0 0 0 0 rgba(190, 109, 109, .75);
            box-shadow: 0 0 0 0 rgba(202, 123, 123, .75), 0 0 0 0 rgba(199, 124, 124, .75);
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-end;

        li {
            a {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                background-color: #F5F5F5;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.15);
                transition: .5s ease;

                svg {
                    z-index: 2;
                    position: relative;

                    path, text {
                        transition: all .6s ${ease};
                    }
                }

                &:after {
                    position: absolute;
                    content: '';
                    height: 0%;
                    width: 0%;
                    border-radius: 50%;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    transition: all .6s ease;
                    background-color: #EC1C24;
                }

                &:hover {


                    &:after {
                        width: 100%;
                        height: 100%;
                    }

                    path, text {
                        fill: #FFF
                    }

                }
            }

            &:nth-of-type(1) {
                img {
                    height: 35px;
                    position: relative;
                    z-index: 2;
                }

                path {
                    fill: #E046AC
                }

            }

            &:nth-of-type(2) {

                a {
                    background-color: #2AAE3D;

                }


                path {
                    fill: #FFF;
                }
            }

            &:nth-of-type(3) {
                tspan, path {
                    fill: #EC1C24;
                    transition: .3s ease;
                }

                a {
                    &:hover {
                        tspan, path {
                            fill: ${whites};
                        }
                    }
                }
            }

            &:nth-of-type(4) a:after {
                background-color: ${text};
            }

            &:not(:nth-last-child(1)) {
                margin-bottom: 20px;
            }

            &.mobile-click {
                display: none;
            }

            &.hotline a {
                background-color: transparent;
                height: auto;
                width: auto;
                box-shadow: none;
                position: relative;

                &:after {
                    display: none;
                }

                img {
                    height: 53px;
                }

                svg {
                    margin-top: 3px;
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    p {
                        font-size: 11px;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }


    @media (min-width: 769px) {
        &:before {
            display: none;
        }

        .hotline {
            &__inner {
                display: none !important;
            }
        }
    }

    @media (max-width: 768px) {
        right: 15px;
        bottom: 120px;
        .hotline {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            //background-color: #fff;
            display: flex !important;
            align-items: center;
            justify-content: center;

            img:not(.hotline__inner) {
                display: none;
            }

            svg path {
                fill: #08082b !important;
            }
        }

        ul li {
            &:not(:nth-last-child(1)) {
                transform: translateY(100%);
                display: none;
                opacity: 0;
            }

            &.mobile-click {
                display: block;
            }
        }
    }

    @keyframes scale {
        0% {
            width: 0;
            height: 0;
        }
        50% {
            width: 100%;
            height: 100%;
        }
        100% {
            width: 0;
            height: 0;
        }
    }

    .mobile-click a:hover {
        path {
            fill: ${text};
        }

        &:after {
            width: 0;
            height: 0;
            animation: scale 1.5s;
            background-color: #acacac;
        }
    }



`;

export default MyComponent;
