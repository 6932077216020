export const apiEndPoints = {
    PAGE_DATA: 'page-data',
    POSTS: 'posts',
    SECTIONS: 'get-req-data/sections',
    PROJECT: 'get-req-data/product-by-cats',
    SAPPHIRE: 'get-req-data/sapphire-by-cats',
    PROJECT_DETAIL: 'get-req-data/product-data',
    MEDIA: 'get-req-data/media-by-cats',
    POST_FORM: 'post-req-data/form-submit',
    SIGN_UP: 'post-req-data/visitor-signup',
    SIGN_IN: 'post-req-data/visitor-login',
    FAVOURITE: 'post-req-data/visitor-favorite',
    LAND_PROJECT: 'get-req-data/lands-by-cats',
    FAVOURITE_PROJECT: 'get-req-data/visitor-favorite-list'
};
