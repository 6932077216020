import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {ease, hover, text, whites} from "../../styles/globalStyleVars";
import Select, {components} from "react-select";
import Project from "../Project";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchData, fetchSapphireData} from "../../api/redux/projects";
import {useDispatch} from "react-redux";

const MyComponent = ({data}) => {
    const dispatch = useDispatch()
    const history = useHistory()

    // dropdown style
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? '#2B3382' : '#FFF',
            margin: 0,
            cursor: 'pointer',
            textTransform: 'capitalize',
            transition: '.3s ease',
            ":hover": {
                backgroundColor: "#08082b",
                color: '#FFF'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,

        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer'
        }),

    };


    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.732" height="7.003"
                             viewBox="0 0 11.732 7.003">
                            <g id="Group_24047" data-name="Group 24047" transform="translate(18853.867 -10435.998)">
                                <g id="Group_24046" data-name="Group 24046"
                                   transform="translate(-18852.863 10442.258) rotate(-93)">
                                    <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none"
                                          stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_12381" data-name="Line 12381" y1="5" x2="5"
                                          transform="translate(0 5)" fill="none" stroke="#08082b" stroke-linecap="round"
                                          stroke-width="1"/>
                                </g>
                            </g>
                        </svg>

                        :

                        <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
                             viewBox="0 0 11.414 6.414">
                            <g id="Group_22451" data-name="Group 22451" transform="translate(10.707 0.707) rotate(90)">
                                <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none" stroke="#08082b"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_12381" data-name="Line 12381" y1="5" x2="5" transform="translate(0 5)"
                                      fill="none" stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>
                    }

                </components.DropdownIndicator>
            )
        );
    };

    // data refactor
    const catFilter = data?.fetchSapphireData?.filter?.category;
    const locationFilter = data?.fetchSapphireData?.filter?.location_list;
    const sizeFilter = data?.fetchSapphireData?.filter?.sizes;
    const typeFilter = data?.fetchSapphireData?.filter?.type;


    // options
    const optionsOne = typeFilter?.map(i => (
        {value: i?.id, label: i?.title.toLowerCase()}
    ))

    const optionsTwo = catFilter?.map(i => (
        {value: i?.id, label: i?.title.toLowerCase()}
    ))

    const location = locationFilter?.map((e, i) => (
        {value: e?.area, label: e?.area}
    ))


    const size = sizeFilter?.map(i => (
        {value: i?.size, label: i?.size}
    ))


    // dropdown handler
    const [area, setArea] = useState('All')
    const [type, setType] = useState('')
    const [status, setStatus] = useState('')
    const [sizes, setSizes] = useState('')
    const [spLocation, setSpLocation] = useState('')

    const handleType = (e) => {
        setType(e)
    }

    const handleLocation = (e) => {
        setSpLocation(e.toLowerCase())
    }

    // get the query param
    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();


    useEffect(() => {
        if (area !== "" || type !== "" || status !== "" || spLocation !== "" || sizes !== "") {
            const setHistory = `/projects/sapphire-series?${area && `area=${area}&`}${type && `type=${type}&`}${status && `status=${status}&`}${spLocation && `location=${spLocation}&`}${sizes && `sizes=${sizes}`}`;
            history.push(setHistory);
        }
    }, [area, type, status, spLocation, sizes])


    // api config
    const endPoints = apiEndPoints.SAPPHIRE


    useEffect(() => {
        const params = {
            [ApiParam.location]: spLocation,
            [ApiParam.image]: 'yes',
        }
        dispatch(fetchSapphireData([endPoints, params]))
    }, [])

    useEffect(() => {
        const params = {
            [ApiParam.location]: spLocation,
            [ApiParam.image]: 'yes',
        }
        dispatch(fetchSapphireData([endPoints, params]))
    }, [spLocation])

    useEffect(() => {
        if (query.get("type") === "land") {
            history.push('/projects/land');
        }
    }, [query])


    //
    const handleArea = (e) => {
        setArea(e)
    }
    const handleStatus = (e) => {
        setStatus(e)
    }

    const handleSize = (e) => {
        setSizes(e)
    }

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        // Filter the data based on the selected filters
        const filtered = data?.fetchSapphireData?.data?.filter(item => {
            // Check if each item matches the selected filters
            return (
                (area === "All" || item.product_data.location.toLowerCase() === area.toLowerCase()) &&
                (type === "" || item.product_data.type_title.toLowerCase() === type.toLowerCase()) &&
                (status === "" || item.product_data.category_title.toLowerCase() === status.toLowerCase()) &&
                (spLocation === "" || item.product_data.area.toLowerCase() === spLocation.toLowerCase()) &&
                (sizes === "" || item.product_data.size.toLowerCase() === sizes.toLowerCase())
            );
        });

        // Update the filtered data state
        setFilteredData(filtered);
    }, [data, area, type, status, spLocation, sizes]);

    console.log(filteredData);
    return (
        <StyledComponent className={'project-list pb-120'}>
            <Container>
                <Row className={'project-list__dis'}>

                    <Col sm={12}>
                        <ul>
                            <li onClick={() => handleArea('All')}
                                className={area === 'All' || area === '' ? 'active' : ''}>
                                <span>All</span></li>
                            <li className={area === 'Dhaka' ? 'active' : ''} onClick={() => handleArea('Dhaka')}>
                                <span>Dhaka</span></li>
                            <li className={area === 'Chittagong' ? 'active' : ''}
                                onClick={() => handleArea('Chittagong')}><span>Chittagong</span></li>
                        </ul>

                    </Col>

                    <Col sm={6} md={3}>
                        <Select onChange={(e) => handleType(e.label)} components={{DropdownIndicator}}
                                styles={customStyles}
                                classNamePrefix={'custom'} className='select-here files'
                                placeholder={'Type'}
                                options={optionsOne}/>
                    </Col>

                    <Col sm={6} md={3}>
                        <Select onChange={(e) => handleStatus(e.label)} components={{DropdownIndicator}}
                                styles={customStyles}
                                classNamePrefix={'custom'} className='select-here files'
                                placeholder={'Status'}
                                options={optionsTwo}/>
                    </Col>

                    <Col sm={6} md={3}>
                        <Select onChange={(e) => handleLocation(e.label)} components={{DropdownIndicator}}
                                styles={customStyles}
                                classNamePrefix={'custom'} className='select-here files'
                                placeholder={'Location'}
                                options={location}/>
                    </Col>

                    <Col sm={6} md={3}>
                        <Select onChange={(e) => handleSize(e.label)} components={{DropdownIndicator}}
                                styles={customStyles}
                                classNamePrefix={'custom'} className='select-here files'
                                placeholder={'Size'}
                                options={size}/>
                    </Col>

                </Row>

                {/*Project list */}

                <Row className={'project'}>
                    {filteredData && filteredData?.length > 0 ? filteredData?.map(i => (
                            <Col key={i?.product_data?.id} md={4} sm={6}>
                                <Project category={i?.product_data?.category_title} area={i?.product_data?.area}
                                         src={`/project/${i?.product_data?.slug}`}
                                         favourite={i?.product_data?.is_favorite}
                                         id={i?.product_data?.id}
                                         title={i?.product_data?.title}
                                         img={i?.images?.list?.find(f => f.thumb === 'on').full_path}/>
                            </Col>
                        )) :
                        <Col>
                            <h4>Coming Soon</h4>
                        </Col>
                    }
                </Row>


            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 60px;
  padding-bottom: 60px !important;

  .project {
    min-height: 80vh;
  }

  .project-list__dis {
    margin-bottom: 60px;

    ul {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #08082B;
      position: relative;
      margin-bottom: 60px;

      li {
        //height: 57px;
        padding: 10px 48px;
        cursor: pointer;
        position: relative;
        transition: color .5s ease;
        font-weight: 600;

        span {
          z-index: 5;
          position: relative;
        }

        &:not(:nth-last-child(1)) {
          border-right: 1px solid #08082B;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 0;
          transition: all .8s ${ease};
          background: ${hover};
          //z-index: -1;
        }

        &:hover, &.active {
          color: #FFF;

          &:before {
            height: 100%;
          }
        }
      }
    }

  }

  //caret
  .custom__control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${text} !important;
    border-radius: 0;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;
    //margin-bottom: 60px;

    .custom__placeholder, .custom__single-value {
      color: ${text};
      font-size: 15px;
      line-height: 20px;
      text-transform: capitalize;
    }

    .custom__value-container {
      padding-left: 0;
    }

    &--is-focused {

    }
  }


  .custom__menu {
    z-index: 9;
  }

  .css-t3ipsp-control:hover {
    border-color: ${text};
  }

  .custom__indicator-separator {
    display: none;
  }

  .custom__indicator {
    padding-right: 0;
  }

  .project-single {
    margin-bottom: 60px;
  }

  @media (min-width: 1650px) {
    .col-md-4 {
      max-width: 25%;
    }
  }

  @media (max-width: 767px) {
    .project-single {
      margin-bottom: 40px;
    }

    .select-here {
      margin-bottom: 20px;
    }

    .project-list__dis {
      margin-bottom: 30px;

      ul {
        margin-bottom: 40px;

        li {
          padding: 10px 25px;
          font-size: 14px;
        }
      }
    }
  }
`;

export default MyComponent;
