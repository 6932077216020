import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import gsap from 'gsap'
import {ease} from "../styles/globalStyleVars";

const MyComponent = () => {

    useEffect(() => {
        document.querySelector('.dark-mode-toggle').addEventListener('click', function () {
            document.body.classList.toggle('dark-mode')
            if (document.body.classList.contains('dark-mode')) {
                sessionStorage.setItem('darkMode', true)
            } else {
                sessionStorage.setItem('darkMode', false)
            }
        });

    }, [])


    useEffect(() => {
        setTimeout(() => {
            if (sessionStorage.getItem('darkMode') == true) {
                document.body.classList.add('dark-mode')
            }
        }, 300)
    }, [sessionStorage])

    useEffect(() => {
        if (window.innerWidth < 769) {
            document.body.classList.add('dark-mode')
        }

    }, [])


    return (
        <StyledComponent className={'dark-mode-toggle'}>
            <img className={'light'} src="/images/static/night.svg" alt=""/>
            <img className={'dark'} src="/images/static/day.svg" alt=""/>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    height: 40px;
    width: 60px;
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    //background-color: rgba(255, 255, 255, 0.14);
    background-color: rgb(0 0 0 / 49%);
    backdrop-filter: blur(5px);
    z-index: 99;
    overflow: hidden;
    cursor: pointer;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 8px;
        margin: auto;
        transition: all .6s ${ease};

        &.light {
            right: 100%;
        }
    }

    @media (max-width: 600px) {
        width: 30px;
        height: 30px;
        img {
            height: 15px;
        }
    }
`;

export default MyComponent;
