import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import Project from "../Project";
import { text, whites } from "../../styles/globalStyleVars";
import { Img } from "../Img";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import { ApiParam } from "../../api/network/apiParams";
import { fetchLand } from "../../api/redux/projects";
import { useDispatch } from "react-redux";

const MyComponent = ({ data }) => {
  const dispatch = useDispatch();

  // dropdown style
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      color: state.isSelected ? "#FFF" : "rgba(0,0,0,0.5)",
      backgroundColor: state.isSelected ? "#2B3382" : "#]212158",
      margin: 0,
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#2B3382",
        color: "#FFF",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "rgba(0,0,0,0.5)",
      backgroundColor: state.isSelected
        ? "rgba(0,0,0,0)"
        : "rgba(255,255,255,0)",
      margin: 0,
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#000" : "#FFF",
      borderRadius: 0,
      cursor: "pointer",
    }),
  };

  // options
  const optionsOne = data?.land?.filter?.category?.map((i) => ({
    value: i?.id,
    label: i?.title,
  }));
  const location = data?.land?.filter?.location_list?.map((i) => ({
    value: i?.location,
    label: i?.location,
  }));

  const [selectCat, setSelectCat] = useState("");
  const [selectLocation, setSelectLocation] = useState("");

  const endPoints = apiEndPoints.LAND_PROJECT;

  const handleCat = (e) => {
    setSelectCat(e);
  };

  const handleLocation = (e) => {
    setSelectLocation(e);
  };

  // api call
  useEffect(() => {
    if (selectLocation || selectCat) {
      const params = {
        [ApiParam.category]: selectCat,
        [ApiParam.location]: selectLocation,
        [ApiParam.image]: "yes",
      };
      dispatch(fetchLand([endPoints, params]));
    }
  }, [selectCat, selectLocation]);

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.732"
              height="7.003"
              viewBox="0 0 11.732 7.003"
            >
              <g
                id="Group_24047"
                data-name="Group 24047"
                transform="translate(18853.867 -10435.998)"
              >
                <g
                  id="Group_24046"
                  data-name="Group 24046"
                  transform="translate(-18852.863 10442.258) rotate(-93)"
                >
                  <line
                    id="Line_12380"
                    data-name="Line 12380"
                    x2="5"
                    y2="5"
                    fill="none"
                    stroke="#08082b"
                    stroke-linecap="round"
                    stroke-width="1"
                  />
                  <line
                    id="Line_12381"
                    data-name="Line 12381"
                    y1="5"
                    x2="5"
                    transform="translate(0 5)"
                    fill="none"
                    stroke="#08082b"
                    stroke-linecap="round"
                    stroke-width="1"
                  />
                </g>
              </g>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.414"
              height="6.414"
              viewBox="0 0 11.414 6.414"
            >
              <g
                id="Group_22451"
                data-name="Group 22451"
                transform="translate(10.707 0.707) rotate(90)"
              >
                <line
                  id="Line_12380"
                  data-name="Line 12380"
                  x2="5"
                  y2="5"
                  fill="none"
                  stroke="#08082b"
                  stroke-linecap="round"
                  stroke-width="1"
                />
                <line
                  id="Line_12381"
                  data-name="Line 12381"
                  y1="5"
                  x2="5"
                  transform="translate(0 5)"
                  fill="none"
                  stroke="#08082b"
                  stroke-linecap="round"
                  stroke-width="1"
                />
              </g>
            </svg>
          )}
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <StyledComponent className={"land-list pt-120 pb-120"}>
      <Container>
        <Row className={"project-list__dis"}>
          <Col sm={6} md={4}>
            <Select
              onChange={(e) => handleCat(e?.value)}
              components={{ DropdownIndicator }}
              styles={customStyles}
              classNamePrefix={"custom"}
              className="select-here files"
              placeholder={"All Lands"}
              options={optionsOne}
            />
          </Col>

          <Col sm={6} md={4}>
            <Select
              onChange={(e) => handleLocation(e?.value)}
              components={{ DropdownIndicator }}
              styles={customStyles}
              classNamePrefix={"custom"}
              className="select-here files"
              placeholder={"Location"}
              options={location}
            />
          </Col>
        </Row>

        <Row className={"land-list__items"}>
          {data?.land?.data?.length > 0 ? (
            data?.land?.data?.map((i) => (
              <Col key={i?.product_data?.title.id} sm={6} md={4}>
                <div className="land-list__items__single">
                  <Link to={`/land/${i?.product_data?.slug}`} />
                  <div className="land-list__items__single__img">
                    <Img src={i?.images?.list?.[0]?.full_path} />
                  </div>
                  <div className="land-list__items__single__des">
                    <h4>{i?.product_data?.title}</h4>
                    <p>
                      {i?.product_data?.area}{" "}
                      {i?.product_data?.location && <span />}
                      {i?.product_data?.location}
                    </p>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <Col>
              <h4>Coming Soon</h4>
            </Col>
          )}
        </Row>
      </Container>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  background-color: #08082b;

  .project-list__dis {
    margin-bottom: 60px;
  }

  //caret
  .custom__control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(245, 245, 245, 0.5) !important;
    border-radius: 0;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;
    //margin-bottom: 60px;

    .custom__placeholder,
    .custom__single-value {
      color: rgba(245, 245, 245, 0.5);
      font-size: 15px;
      line-height: 20px;
    }

    .custom__value-container {
      padding-left: 0;
    }

    &--is-focused {
    }
  }

  .custom__menu {
    z-index: 9;
  }

  .css-t3ipsp-control:hover {
    stroke: rgba(245, 245, 245, 0.5);
  }

  .custom__indicator-separator {
    display: none;
  }

  .custom__indicator {
    padding-right: 0;
  }

  line {
    stroke: rgba(245, 245, 245, 0.5);
  }

  .land-list__items {
    .col-md-4 {
      margin-bottom: 30px;
    }

    &__single {
      position: relative;
      //margin-bottom: 30px;
      background-color: rgb(33, 33, 88);
      min-height: 100%;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 2;
      }

      &__img {
        padding-top: calc(310 / 370 * 100%);
        position: relative;
        overflow: hidden;
      }

      &__des {
        //background-color: #212158;
        background-color: rgba(0, 0, 0, 0.7);
        //padding: 20px 15px;
        padding: 10px 15px;
        transition: 0.3s ease;
        position: absolute;
        bottom: 30px;
        width: 100%;

        h4 {
          //font-size: 24px;
          font-size: 22px;
          line-height: 32px;
          color: ${whites};
          //margin-bottom: 5px;
          margin-bottom: 0;
        }

        p {
          color: ${whites};
          position: relative;
          display: flex;
          align-items: center;

          span {
            height: 4px;
            width: 4px;
            border-radius: 50%;
            background-color: #fff;
            margin: 0 8px;
            display: inline-block;
          }
        }
      }

      &:hover {
        .land-list__items__single__des {
          background-color: rgba(33, 33, 88, 0.89);
        }

        img {
          transform: scale(1.04);
        }
      }
    }
  }

  @media (max-width: 575px) {
    .project-list__dis .col-md-4:nth-of-type(1) {
      margin-bottom: 20px;
    }
  }
`;

export default MyComponent;
