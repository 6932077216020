import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Team from "../../components/management-team/Team";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchPosts} from "../../api/redux/about";
import Founder from "../../components/about/Founder";
import ManagementTeam from "../../components/about/ManagementTeam";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/animations/PageTransition";
import Recognitions from "../../components/management-team/Recognitions";

const MyComponent = () => {


    const dispatch = useDispatch()
    let getPost = useSelector(state => state.aboutReducer)

    // api config
    const endPoints = apiEndPoints.SECTIONS
    const params = {
        [ApiParam.type]: 'slug',
        [ApiParam.value]: 'about-us',
        [ApiParam.get_section]: 'yes',
        [ApiParam.image]: 'yes',
        [ApiParam.post]: 'yes',
    }
    // api call
    useEffect(() => {
        dispatch(fetchPosts([endPoints, params]))
    }, [])

    // const leaderList = getPost?.management?.team?.list;
    // const managementList = leaderList?.filter(item => item?.page_data?.role === 'management');
    const leaderList = getPost?.data?.team?.list;

    const FounderData = getPost?.data?.data?.sections?.find(f => f?.section_data?.id == 84)
    //Leaders List Start
    const leaders = leaderList?.filter(item => item?.page_data?.role === 'leader');

    //Management List Start
    const managementList = leaderList?.filter(item => item?.page_data?.role === 'management');

    const Banner = getPost?.data?.management_banner?.page_images?.list?.find(f => f?.mobile !== "on")?.full_path


    const recognition = getPost?.data?.data?.sections?.find(f => f?.section_data?.slug === 'recognitions')
    

    return (
        <HelmetProvider>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Management Team | Sheltech Pvt Ltd</title>
            </Helmet>

            <StyledComponent>
                <InnerBanner img={Banner} title={getPost?.data?.management_banner?.page_data?.short_desc}
                             subTitle={getPost?.data?.management_banner?.page_data?.subtitle}/>
                <Team data={leaders}/>
                <Founder data={FounderData}/>
                <ManagementTeam data={managementList}/>
                <Recognitions data={recognition}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
    @media (max-width: 600px) {
        .management-team__list {
            margin-top: 0;
        }
    }
`;

export default MyComponent;
