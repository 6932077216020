import { createGlobalStyle, css } from "styled-components";
import { hover, text, title, whites } from "./globalStyleVars";

function createCSS() {
  let styles = "";

  for (let i = 2; i < 20; i += 1) {
    styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * 0.12}s;
        }
     `;
  }

  for (let a = 2; a < 100; a += 1) {
    styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * 0.03}s;
        }
     `;
  }

  return css`
    ${styles}
  `;
}

export default createGlobalStyle`

    ${createCSS()}
    #root {
        min-height: 100vh;
        overflow-x: hidden;
        //display: none;
    }

    @font-face {
        font-family: 'Banana Grotesk';
        src: url('/fonts/BananaGrotesk-Light.woff2') format('woff2'),
        url('/fonts/BananaGrotesk-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Banana Grotesk';
        src: url('/fonts/BananaGrotesk-Bold.woff2') format('woff2'),
        url('/fonts/BananaGrotesk-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Banana Grotesk';
        src: url('/fonts/BananaGrotesk-Regular.woff2') format('woff2'),
        url('/fonts/BananaGrotesk-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }


    @font-face {
        font-family: 'sangbleu';
        src: url('/fonts/sangbleu-sans-regular.woff2') format('woff2'),
        url('/fonts/sangbleu-sans-regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }


    body {
        font-family: 'Banana Grotesk', Arial, Helvetica, freesans, sans-serif !important;
        font-style: normal;
        margin: 0;
        color: ${text};
        padding: 0;
        overflow-x: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100vh;
        font-size: 15px;
        line-height: 22px;
         font-weight: 400;

        /* Hide scrollbar for Chrome, Safari and Opera */
        //overscroll-behavior: none;
        //cursor: none;
        // pointer-events: none;

        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */


        &.stop-scroll {
            overflow: hidden;
        }


        @media (max-width: 767px) {
            cursor: pointer;
        }

    }

    a {
        transition: color .3s ease;
        text-decoration: none;

        &:hover {
            color: ${hover} !important;
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }

        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
            color: ${text};
        }
    }

    ::selection {
        background: #DDD;
        color: ${hover};
    }

    p, a, h4, h3, h5, h6 {
        color: ${text};
        font-weight: 400;
        margin: 0;
    }

    h1, h2 {
            //font-family: ${title};
        margin: 0;
    }

    ul {
        margin: 0;
        padding: 0
    }

    li {
        list-style: none;
    }

    img {
        max-width: 100%;
        object-fit: contain;
    }


    .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
        outline: none;
        box-shadow: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid rgba(0, 0, 0, 0);
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
        transition: background-color 5000s ease-in-out 0s;
    }


    table {
        width: 100%;
    }

    form div {
        position: relative;
    }

    .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;

        &:focus {
            box-shadow: none;
        }
    }

    .p-0 {
        padding: 0 !important;
    }

    .pl-0 {
        padding-left: 0;
    }

    .pr-0 {
        padding-right: 0;
    }

    .pt-200 {
        padding-top: 200px;
        @media (max-width: 991px) {
            padding-top: 90px;
        }
    }

    .pb-200 {
        padding-bottom: 200px;
        @media (max-width: 991px) {
            padding-bottom: 90px;
        }
    }

    .pt-150 {
        padding-top: 150px;
        @media (max-width: 991px) {
            padding-top: 90px;
        }
    }

    .pb-150 {
        padding-bottom: 150px;
        @media (max-width: 991px) {
            padding-bottom: 90px;
        }
    }

    .pt-120 {
        padding-top: 120px;
        @media (max-width: 767px) {
            padding-top: 80px;
        }
    }

    .pb-120 {
        padding-bottom: 120px;
        @media (max-width: 767px) {
            padding-bottom: 80px;
        }
    }

    .pb-130 {
        padding-bottom: 130px;
        @media (max-width: 991px) {
            padding-bottom: 80px;
        }
    }

    .pt-100 {
        padding-top: 100px;

    }

    .pb-100 {
        padding-bottom: 100px;

    }

    .pt-80 {
        padding-top: 80px;
        @media (max-width: 767px) {
            padding-top: 120px;
        }
    }

    .pb-80 {
        padding-bottom: 80px;
        @media (max-width: 767px) {
            padding-bottom: 120px;
        }
    }


    .MuiDrawer-paper {
        width: 500px !important;
        padding: 20px;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    //.swiper-button-disabled {
    //  opacity: 0 !important;
    //}

    @media (min-width: 1500px) {
        .container {
            //min-width: 1366px;
            min-width: 85%;
            margin: auto;
        }
    }

    @media (max-width: 1199px) and (min-width: 768px) {
        .container, .container-lg, .container-md, .container-sm {
            max-width: 90%;
            margin: auto;
        }
    }


    @media (max-width: 767px) {
        .container, .container-sm {
            max-width: 100%;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }


    //react select
    .css-yk16xz-control, .css-1pahdxg-control {
        height: 50px;
        border-radius: 0 !important;
        padding-left: 5px;
        font-size: 16px;
        outline: none !important;
        border-color: #D9D9D9 !important;
        box-shadow: none !important;

        .css-1wa3eu0-placeholder {
            font-weight: 300;
            line-height: 21px;
            color: rgba(0, 0, 0, 0.5);
            outline: none;
        }

        .css-1okebmr-indicatorSeparator {
            display: none;
        }

        .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
            margin-right: 10px;
        }
    }

    .css-2613qy-menu {
        border-radius: 0 !important;
        margin-top: 0 !important;
    }

    //animation class


    .info-window {
        max-width: 200px;
    }

    .gm-style-iw {
        border-radius: 0 !important;
    }

    .swiper-pagination-bullet {
        outline: none;
    }

    .css-nmuc1a-menu {
        z-index: 5 !important;
    }


    .map-info__img {
        img {
            height: 100px;
            margin-bottom: 12px;
            object-fit: cover;
        }
    }

    .map-info__content {
        h4 {
            font-size: 20px;
        }

        p {
            margin-bottom: 5px;
        }
    }

    .overlay {
        position: fixed;
        height: 100vh;
        width: 100%;
        //background-color: rgba(0,0,0,0.5);
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 9;
        display: none;

        &.show {
            display: block;
        }
    }

    .form-control.has-error {
        border-color: #dc004e !important;
    }

    .has-error .find-retainer-filter__control {
        border-color: #dc004e !important;
    }

    //preloader
    .content-loader {
        position: absolute;
        height: 70%;
        width: 70%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .loading-before-submit {
        position: fixed;
        height: 100vh;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.65);
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 40px;
        }
    }


    .slick-slide {
        div {
            outline: none !important
        }
    }

    button, button:active, button:focus, button:focus-visible {
        outline: none !important;
        box-shadow: none !important;
    }


    .hover {
        position: relative;
        overflow: hidden;

        span {
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: ${hover};
            transition: all .5s ease;
            border-radius: 19px;
        }

        &:hover {
            &:after {
                height: 100%;
                width: 100%;
            }
        }
    }


    .modal-backdrop {
        background-color: rgb(34 31 31 / 90%);
        min-width: 100%;
        //z-index: 9999;
        z-index: 99999;

        &.show {
            opacity: 1;
        }
    }


    .valid {
        color: ${hover};
        position: absolute;
        font-size: 12px;
        top: 44px;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }


    //menu fixed
    //.scroll-down .main-menu {
    //  transform: translate3d(0, -100%, 0);
    //}
    //
    //.scroll-up .main-menu {
    //  background-color: #F5F5F5;
    //  box-shadow: 0 0 10px #ddddddc2;
    //
    //  .main-menu__logo a {
    //
    //
    //    &:nth-of-type(1) {
    //      display: none;
    //    }
    //
    //    &:nth-of-type(2) {
    //      display: flex !important;
    //    }
    //
    //  }
    //
    //  .slide:after {
    //    background-color: #D6D6D6 !important;
    //  }
    //
    //  .hamburger {
    //    span {
    //      background-color: #08082B !important;
    //    }
    //  }
    //
    //  .main-menu__bar {
    //    ul {
    //      li:not(:nth-last-child(1)) {
    //        border-right: 1px solid #D6D6D6;
    //      }
    //
    //      li:nth-of-type(1) {
    //        border-left: 1px solid #D6D6D6;
    //      }
    //
    //      path {
    //        fill: #08082B;
    //      }
    //    }
    //  }
    //}

    .form-control:disabled {
        background-color: transparent;
    }

    @media (max-width: 600px) {
        .prevent-overflow {
            overflow: hidden;
            height: 100vh;
        }
    }

    .Toastify__toast-container {
        z-index: 99999999;
    }

    .mobile-menu {
        #fb-root, .fb_reset {
            display: none !important;
            opacity: 0 !important;
            visibility: hidden !important;
        }
    }

    .slick-slide {
        -webkit-transform: translate3d(0, 0, 0);
    }

    .reveal, .revealFast {
        overflow: hidden;
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;

        img {
            transform-origin: left;
        }

        .global-image {
            background: transparent;
        }
    }

    //calender
    ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
        border-radius: 0 !important;
    }

    //video modal 
    .modal-video-close-btn:before, .modal-video-close-btn:after {
        background-color: ${hover};
    }

    //page loader
    .page-loader {
        position: fixed;
        background-color: #08082b;
        width: 100vw;
        z-index: 999999999;
        overflow: hidden;
        height: 100vh;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        //align-items: center;
        justify-content: center;

        svg {
            width: 270px;
            position: absolute;
            top: calc(50vh - 30px);
        }

        .pre-loader__img {
            //position: fixed;
            height: 100px;
            width: 100px;
            top: 50vh;
            left: 0;
            right: 0;
            background-color: rgb(36, 24, 67);
            z-index: 99999999;

            //flex: 1;
            margin: auto;
            opacity: 0;

            img {
                display: block;
            }
        }

    }

    //------------------------animation
    .split-up {
        width: 100%;
    }

    //text animation 
    .split-parent {
        overflow: hidden;
    }

    .split-child {
        overflow: hidden;
    }


    .reveal {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            transform-origin: left;
        }

        .global-image {
            background: transparent;
        }
    }


    //page transition
    .page-transition {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: ${hover};
        z-index: 999999999;
        //display: none;
        //opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            z-index: 99;
        }
    }


    //video modal
    .modal-video {
        background-color: transparent;
        height: 100vh;
        z-index: 99999;

        .modal-dialog {
            height: 100vh;
            background-color: transparent;
            min-width: 100%;
            margin: 0;
        }

        .modal-body {
            height: 100vh;
        }

        .modal-content {
            background-color: transparent;

        }

        iframe {
            height: 60vh;
            width: 60vw;
            margin: auto;
            position: absolute;
            inset: 0;
        }

        .close-modal {
            position: absolute;
            top: 40px;
            right: 30px;
            height: 30px;
            cursor: pointer;
            z-index: 99;
        }

        @media (max-width: 768px) {
            .modal-content {
                //padding: 0 20px;

                iframe {
                    width: 90vw;
                    height: 60vh;
                }

                .close-modal {
                    top: 80px;
                    right: 20px;
                }
            }
        }
        @media (max-width: 550px) {
            .modal-content iframe {
                width: 90vw;
                height: 40vh;
            }
        }
    }

    //founder popup
    .founder-modal {
        background-color: #08082B;
        height: 100vh;
        z-index: 99999;

        overflow: hidden !important;


        .modal-dialog {
            height: 100vh;
            background-color: transparent;
            min-width: 100%;
            margin: 0;
        }

        .modal-body {
            height: 100vh;
        }

        .modal-content {
            background-color: transparent;
            padding: 100px 0;

            h3 {
                font-size: 36px;
                line-height: 44px;
                margin-bottom: 30px;
                color: #F5F5F5;
            }

            h4 {
                font-size: 15px;
                line-height: 20px;
                margin-bottom: 30px;
                color: #F5F5F5;

                span {
                    display: block;
                }
            }

            p {
                color: rgba(245, 245, 245, 0.6);

                &:not(:nth-last-child(1)) {
                    margin-bottom: 20px;
                }
            }
        }

        @media (max-width: 767px) {
            .modal-content {
                padding-top: 80px;

                .container {
                    height: calc(100vh - 100px);
                    overflow: scroll;

                    h3 {
                        margin-top: 30px;
                        font-size: 28px;
                        line-height: 35px;
                    }
                }
            }

            .founder-modal__img {
                height: 50vh;
            }

        }
    }


    //associalte modal 
    .associate-modal {
        .founder-modal__img {
            padding-top: 100%;
            background-color: #fff;
            position: relative;

            img {
                object-fit: contain;
                width: auto;
                height: auto;
                margin: auto;
            }
        }

        .founder-modal__add {
            margin-top: 30px;

            img {
                height: 12px;
            }

            span {
                display: inline-flex;
                height: 35px;
                width: 35px;
                border-radius: 50%;
                border: 1px solid rgb(255, 255, 255);
                align-items: center;
                justify-content: center;
                margin-right: 8px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                }
            }

            a {
                color: ${whites};
                word-break: break-all;
                transition: color .3s ease;

                &:hover {
                    color: rgba(255, 255, 255, 0.5) !important;

                    img {
                        opacity: .5;
                    }
                }
            }

            h6 {
                color: ${whites};
                margin-bottom: 20px;
                font-size: 15px;
                line-height: 20px;
                width: 90%;
            }

            p {
                color: ${whites};
                margin-bottom: 6px !important;

                //&:nth-of-type(1) {
                //  margin-bottom: 30px !important;
                //  width: 90%;
                //}
            }
        }

        .associate-modal__slide {
            width: 100%;
            margin-bottom: 40px;

            &__single {
                position: relative;
                padding-top: calc(504 / 770 * 100%);
            }

            ul {
                display: flex;
                justify-content: end;
                margin-top: 30px;
            }
        }
    }

    //team popup
    .team-modal {
        background-color: #08082B;
        height: 100vh;
        z-index: 99999;
        overflow: hidden !important;
        width: 540px;
        right: 0;
        left: auto;
        padding: 0px 70px 30px 70px !important;

        .close-button {
            //right: 70px;
            //position: fixed;
            //opacity: 0;
            ////transition-delay: .6s;
            right: -10px;
            top: -70px;
            position: absolute;
        }

        .modal-dialog {
            height: 100vh;
            background-color: transparent;
            min-width: 100%;
            margin: 0;
        }

        .modal-body {
            height: 100vh;
        }

        .modal-content {
            background-color: transparent;
            margin: 100px 0;
            //overflow: scroll;
            //height: calc(100vh - 120px);

            .team-modal__content {
                color: #f5f5f5;

                img {
                    max-width: 80%;
                    @media (max-height: 700px) {
                        max-width: 45%;
                    }
                }

                h4 {
                    color: #f5f5f5;
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 20px;
                    padding-top: 20px;
                    border-top: 1px solid rgba(245, 245, 245, 0.2);
                    margin-bottom: 5px;
                }

                h5 {
                    color: rgba(245, 245, 245, 0.5);
                    font-size: 20px;
                }

                p {
                    color: #f5f5f5;
                }

                ul {
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    li {
                        &:not(:nth-last-child(1)) {
                            margin-right: 20px;
                        }

                        a {
                            transition: opacity .5s ease;

                            &:hover {
                                // rect {
                                    //   fill: ${text}
                                // }
                                //
                                // path {
                                    //   fill: ${whites}
                                // }
                                opacity: .7;
                            }
                        }

                    }
                }
            }
        }

        @media (max-width: 767px) {
            width: 100%;
            padding: 0 20px 10px 20px !important;
            .close-button {
                right: 0px;
                //position: fixed;
            }

            .modal-content {
                //padding-top: 80px;

                .container {
                    //height: calc(100vh - 100px);
                    //overflow: scroll;

                    h3 {
                        margin-top: 30px;
                        font-size: 28px;
                        line-height: 35px;
                    }
                }
            }

            //overflow: scroll !important;

        }
    }

    .subscribe-modal {
        .from-group {
            margin-bottom: 25px;

            &:nth-last-child(1) {
                margin-bottom: 0;
            }
        }

        .form-control {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #F5F5F5;
            padding-left: 0;
            height: 45px;
            //margin-bottom: 25px;
            color: #F5F5F5;

            &::placeholder {
                font-size: 15px;
                color: ${whites};
            }
        }
    }


    .modal-open {
        .close-button {
            opacity: 1;
            transition-delay: .6s;
        }
    }

    .simplebar-scrollbar:before {
        background-color: #FFF !important;
    }

    .simplebar-track.simplebar-vertical {
        width: 7px;
    }

    .simplebar-track {
        right: -7px;
    }

    .career-modal {

        .modal-content .team-modal__content {
            h3 {
                font-size: 20px;
                line-height: 28px;
                color: ${whites};
                padding-bottom: 30px;
                margin-bottom: 30px;
                border: none;
                border-bottom: 1px solid #F5F5F5;
            }

            h5 {
                font-size: 20px;
                line-height: 28px;
                color: ${whites};
                margin-top: 30px;
            }

            ul {
                flex-wrap: wrap;

                li {
                    color: ${whites};
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    width: 100%;

                    &:not(:nth-last-child(1)) {
                        border-bottom: 1px solid rgba(245, 245, 245, 0.2);
                    }
                }
            }
        }

        //.close-button {
        //  right: -10px;
        //  top: -70px;
        //  position: absolute;
        //}
    }

    //----dark mode toggle 
    .dark-mode {
        .dark-mode-toggle {
            img.dark {
                right: 100%
            }

            img.light {
                right: 8px;
            }
        }
    }

    .form-error {
        position: absolute;
        font-size: 12px;
        color: #EC1C23 !important;
    }

    .menu-open {
        @media (max-width: 1200px) {
            height: 100vh !important;
            overflow: hidden;
        }

    }

    button.gm-ui-hover-effect {
        right: 0 !important;
        top: 0 !important;
    }

    //section {
    //  margin-bottom: -2px;
    //}

    .as-landowner {
        .modal-content .team-modal__content h4 {
            border-top: 0;
            margin-top: 0;
            padding-top: 0;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(245, 245, 245, 0.2);
            padding-bottom: 20px;
        }
    }

    //.no-line {
    //  margin-bottom: -2px;
    //  overflow: hidden;
    //}
    #smooth-content {
        will-change: transform;
    }

    .lg-on {
        .main-menu {
            opacity: 0 !important;
            z-index: -9 !important;
        }
    }

    .map-pupup {
        width: 200px;
        padding-right: 10px;

        p {
            color: ${hover} !important;
            line-height: 18px;
        }
    }

    .as-awards {
        .team-modal__content img {
            margin-bottom: 30px;
            max-width: 90%;
        }
    }

`;
