import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPosts, fetchPostDetail } from "../../api/redux/post";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { Img } from "../../components/Img";
import Title from "../../components/Title";
import InnerBanner from "../../components/InnerBanner";
import { Loading } from "../../components/Loading";
import { hover, title } from "../../styles/globalStyleVars";
import FeatureProject from "../../components/home/FeatureProject";
import Video from "../../components/home/Video";
import Sapphire from "../../components/home/Sapphire";
import Excellence from "../../components/home/Excellence";
import Testimonial from "../../components/Testimonial";
import Insigths from "../../components/home/Insigths";
import CloseButton from "../../components/CloseButton";
import { ApiParam } from "../../api/network/apiParams";
import { fetchData } from "../../api/redux/home";
import GoogleMap from "../../components/home/GoogleMap";
import BannerSlider from "../../components/home/BannerSlider";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { PageAnimation } from "../../components/animations/PageTransition";
import { motion } from "framer-motion";
import Popup from "../../components/home/Popup";

const Home = () => {
  const dispatch = useDispatch();
  let getPost = useSelector((state) => state.homeReducer);

  // api config
  const endPoints = apiEndPoints.SECTIONS;
  const params = {
    [ApiParam.type]: "slug",
    [ApiParam.value]: "home",
    [ApiParam.get_section]: "yes",
    [ApiParam.image]: "yes",
    [ApiParam.post]: "yes",
  };
  // api call
  useEffect(() => {
    dispatch(fetchData([endPoints, params]));
  }, []);

  // home data refactor
  const featureProject = getPost?.data?.featured_products?.list;
  const PromotionalPopup = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 125
  );
  const banner = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 47
  );
  const Videos = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 2
  );
  const SapphireData = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 56
  );
  const Heritage = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 3
  );
  const Testimonials = getPost?.data?.data?.sections?.find(
    (f) => f.section_data?.id == 102
  );
  const TestimonialsList = getPost?.data?.success_story?.list;
  const mediaCenter = getPost?.data?.media_center;
  const dynamicMap = getPost?.data?.products_map_list?.list;
  // const popupData = getPost?.data?.data?.sections?.find(
  //   (f) => f?.section_data?.id === 125
  // );

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Best Real Estate Developer in Bangladesh | Sheltech Pvt Ltd
        </title>
      </Helmet>
      <StyledSection className={"in-home"}>
        <BannerSlider data={banner} />
        <FeatureProject data={featureProject} />
        <Video data={Videos} />
        <Sapphire list={getPost?.data?.sapphire_series} data={SapphireData} />
        <GoogleMap data={dynamicMap} />
        <Excellence data={Heritage} />
        <Testimonial testimonial={TestimonialsList} data={Testimonials} />
        <Insigths data={mediaCenter} />
      </StyledSection>
    </HelmetProvider>
  );
};

const StyledSection = styled.section`
  overflow: hidden;

  .video-modal {
    background-color: rgb(33, 33, 88);

    &:after {
      background-color: rgb(33, 33, 88);
    }
  }

  //section {
  //  margin-bottom: -2px;
  //  overflow: hidden;
  //}
`;

export default Home;
