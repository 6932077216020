import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import SliderNextWhite from "../SliderNextWhite";
import SliderPrevWhite from "../SliderPrevWhite";
import Button from "../Button";
import Media from "../Media";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import {whites} from "../../styles/globalStyleVars";
import Project from "../Project";


const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'insights related-project pb-200 pt-200'}>
            <Container>
                <Row>
                    <Col className="insights__top d-flex justify-content-between">

                        <Title text={'Related Projects'}/>
                        <div className="insights__top__nav">
                            <ul>
                                <li className={'slider_prev'}><SliderPrevWhite borderColor={'#08082B'} color={'#08082B'}
                                                                               hoverBackground={'#08082B'}
                                                                               hoverColor={'#FFF'}/></li>
                                <li className={'slider_next'}><SliderNextWhite borderColor={'#08082B'} color={'#08082B'}
                                                                               hoverBackground={'#08082B'}
                                                                               hoverColor={'#FFF'}/></li>
                            </ul>
                            <Button src={'/projects'} color={'#08082B'} hoverBackground={'#08082B'}
                                    hoverColor={'#FFF'}
                                    borderColor={'#08082B'}
                                    text={'View All'}/>
                        </div>

                    </Col>
                </Row>

                <div className="insights__slider">
                    {data?.length > 0 &&
                        <Swiper loop={false}
                                spaceBetween={30}
                                slidesPerView={1}
                                allowSlideNext={true}
                                allowSlidePrev={true}
                            // allowTouchMove={false}
                                speed={900}
                            // pagination={{
                            //     type: "fraction",
                            // }}
                                navigation={{
                                    prevEl: '.insights .slider_prev',
                                    nextEl: '.insights .slider_next ',
                                }}
                                modules={[Autoplay, Pagination, Navigation]}

                            // autoplay={false}

                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    1600: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },

                                    991: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },

                                    500: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },

                                }}>
                            {data?.length > 0 && data?.map(i => (
                                <SwiperSlide key={i?.product_data?.id}>
                                    <Project area={i?.product_data?.area} category={i?.product_data?.category_title}
                                             title={i?.product_data?.title}
                                             img={i?.images?.list?.find(f => f?.thumb === 'on').full_path}
                                             src={`/project/${i?.product_data?.slug}`}/>
                                </SwiperSlide>
                            ))}


                        </Swiper>
                    }
                </div>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  //background-color: #D6D6D6;
  overflow: hidden;
  position: relative;

  // &:after {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 50%;
  //   bottom: 0;
  //   left: 0;
    //   background-color: ${whites};
  // }

  .title {
    margin: 0 !important;
  }

  .insights__top {
    margin-bottom: 80px;

    &__nav {
      display: flex;
      align-items: center;

      ul {
        display: flex;
        margin-right: 60px;
      }
    }
  }

  .insights__slider {
    width: 100%;
  }


  @media (max-width: 991px) {
    .insights__top {
      flex-wrap: wrap;
      margin-bottom: 40px;

      &__nav {
        width: 100%;
        margin-top: 30px;
        justify-content: space-between;

        ul {
          margin-right: 40px;
        }
      }
    }
  }
`;

export default MyComponent;
