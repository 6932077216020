import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import Button from "../Button";
import {hover, whites} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import CloseButton from "../CloseButton";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [offset, setOffset] = useState(0)


    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft + 15)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.container').offsetLeft + 15)
        })
    }, [])

    return (
        <StyledComponent offset={offset} className={'founder'}>
            <Container fluid style={{paddingRight: offset + 'px'}}>
                <Row>
                    <Col sm={7} className={'founder__text'} style={{paddingLeft: offset + 'px'}}>
                        <h3>{data?.section_data?.subtitle}</h3>
                        <h4>{reactHtmlParser(data?.section_data?.short_desc)}</h4>
                        {reactHtmlParser(data?.section_data?.description)}
                        {/*<div onClick={handleShow}>*/}
                        {/*    <Button margin={'40px 0 0 0'} color={'#08082B'} borderColor={'#08082B'}*/}
                        {/*            hoverBackground={'#08082B'}*/}
                        {/*            hoverColor={'#FFF'} text={'Read More'}/>*/}
                        {/*</div>*/}

                    </Col>

                    <Col sm={5} className={'pr-0'}>
                        <div className="founder__img">
                            <Img src={data?.images?.list?.[0]?.full_path}/>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal className={'founder-modal'} show={show} onHide={handleClose}>
                <div onClick={handleClose}>
                    <CloseButton/>
                </div>

                <Container>
                    <Row>
                        <Col md={4} className={'founder-modal__img'}>
                            <Img src={data?.images?.list?.[0]?.full_path}/>
                        </Col>

                        <Col md={{span: 7, offset: 1}}>
                            {window?.innerWidth > 767 ?

                                <SimpleBar style={{maxHeight: window.innerHeight - 200 + 'px'}}>
                                    <h3>{data?.section_data?.subtitle}</h3>
                                    <h4>{reactHtmlParser(data?.section_data?.short_desc)}</h4>
                                    {reactHtmlParser(data?.section_data?.description)}
                                </SimpleBar>

                                :

                                <>
                                    <h3>{data?.section_data?.subtitle}</h3>
                                    <h4>{reactHtmlParser(data?.section_data?.short_desc)}</h4>
                                    {reactHtmlParser(data?.section_data?.description)}
                                </>

                            }


                        </Col>
                    </Row>
                </Container>

            </Modal>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    // background-color: ${whites};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    width: 100%;
    height: 50%;
    background-color: rgb(245, 245, 245);
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50%;
    background-color: #D6D6D6;
  }

  .row {
    position: relative;
    z-index: 2;
    background-color: #fff;
  }

  .founder__text {
    padding: 60px 80px 50px 0;

    h3 {
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 30px;
    }

    h4 {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 30px;

      span {
        display: block;
      }
    }

    p {
      &:not(:nth-last-child(1)) {
        margin-bottom: 18px;
      }

      &:nth-of-type(n+3) {
        display: none;
      }
    }
  }

  .founder__img {
    position: relative;
    padding-top: calc(547 / 570 * 100%);
    min-height: 100%;
  }


  @media (max-width: 850px) {
    .founder__img {
      min-height: unset;
    }
  }

  @media (max-width: 768px) {
    .row {
      flex-direction: column-reverse;
    }

    .founder__text {
      min-width: 100%;
    }

    .founder__img {
      min-height: unset;
      margin-left: ${p => p.offset - 15}px !important;
    }
  }


  @media (max-width: 767px) {
    .col-sm-5 {
      min-width: 100%;
      padding-right: 15px !important;
    }


    .founder__text {
      padding: 40px 15px 0px 15px !important;

      h3 {
        font-size: 28px;
        line-height: 35px;
      }
    }
  }
`;

export default MyComponent;
