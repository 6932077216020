import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import LandProjectList from "../../components/project/LandProjectList";
import InnerBanner from "../../components/InnerBanner";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import { ApiParam } from "../../api/network/apiParams";
import { fetchLand } from "../../api/redux/projects";
import { motion } from "framer-motion";
import { PageAnimation } from "../../components/animations/PageTransition";

const MyComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let getPost = useSelector((state) => state.projectReducer);

  const endPoints = apiEndPoints.LAND_PROJECT;

  useEffect(() => {
    const params = {
      [ApiParam.category]: "",
      [ApiParam.location]: "",
      [ApiParam.image]: "yes",
    };
    dispatch(fetchLand([endPoints, params]));
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Land Projects | Sheltech Pvt Ltd</title>
      </Helmet>

      <StyledComponent className={"land-project"}>
        <InnerBanner
          subTitle={getPost?.land?.project_data?.page_data?.title}
          title={getPost?.land?.project_data?.page_data?.short_desc}
          img={getPost?.land?.project_data?.page_images?.list[0]?.full_path}
        />
        <LandProjectList data={getPost && getPost} />
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
  //section {
  //  overflow: hidden;
  //  margin-bottom: -2px;
  //}
`;

export default MyComponent;
