import React, {useState} from 'react';
import {GoogleMap, LoadScript, Marker, InfoWindow, StyledMap} from '@react-google-maps/api';
import Button from "./../Button";
import styled from 'styled-components';
import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
    useLightgallery
} from "react-lightgallery";
import {Img} from "../Img";
import {hover} from "../../styles/globalStyleVars";

const GoogleMapsMarkers = ({data}) => {

    const mapStyles = {
        height: '100%',
        width: '100%',
    };

    const defaultCenter = {
        lat: 23.801927, // Replace with marker latitude
        lng: 90.4029675, // Replace with marker longitude
    };

    const [selectedMarker, setSelectedMarker] = useState(null);


    const markers = data && Array.isArray(data) ? data.map((item, index) => {
        return {
            id: index,
            position: {lat: parseFloat(item?.product_data?.latitude), lng: parseFloat(item?.product_data?.longitude)},
            icon: '/images/static/map.svg',
            content: item?.product_data?.title,
            type: item?.product_data?.location,
            type2: item?.product_data?.area,
            size: item?.product_data?.size_sft,
            bedroom: item?.product_data?.bedroom,
            handover: item?.product_data?.handover,
            image: item?.images?.list,
            slug: item?.product_data?.slug,
        };
    }) : [];


    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
    };

    const handleInfoWindowClose = () => {
        setSelectedMarker(null);
    };


    const customMapStyle = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ]; // Paste your copied JSON style here

    const mapOptions = {
        styles: customMapStyle,
    };


    const [selectedMarkerId, setSelectedMarkerId] = useState(null);
    const handleButtonClick = (selectedId) => {

        setSelectedMarkerId(selectedId)
    };
    const handleButtonClickClose = () => {
        setSelectedMarkerId(null)
    };

    const [mapLoaded, setMapLoaded] = useState(false);

    const handleMapButtonClick = () => {
        setMapLoaded(true);
    };


    return (
        <StyledComponent>
            {!mapLoaded && (
                <div className={"map-overlay"}>
                    <div className="map-overlay__btn" onClick={handleMapButtonClick} style={{width: "fit-content"}}>
                        <Button margin={"0"} text={"Click to load the map"} src={'#'}/>
                    </div>
                    {/*// <button onClick={handleMapButtonClick}>Load Map</button>*/}
                </div>

            )}
            {
                mapLoaded ?
                    <>
                        <LoadScript googleMapsApiKey="AIzaSyB3nEqKsliw_EQSpaxAq6R8hOUwpGyxJ9s"
                                    onError={(error) => console.error('Error Laoding Google Map', error)}
                        >
                            <GoogleMap
                                mapContainerStyle={mapStyles}
                                zoom={11}
                                center={defaultCenter}
                                options={mapOptions}
                            >
                                {markers.map((marker) => (
                                    <Marker
                                        key={marker.id}
                                        position={marker.position}
                                        icon={marker.icon}
                                        onClick={() => handleMarkerClick(marker)}
                                    >
                                    </Marker>
                                ))}
                                {selectedMarker && (
                                    <InfoWindow
                                        position={selectedMarker.position}
                                        onCloseClick={handleInfoWindowClose}
                                    >
                                        <div style={{cursor: "pointer"}} data-id={selectedMarker.id}
                                             onClick={() => handleButtonClick(selectedMarker.id)}>
                                            <ul>
                                                <li>{selectedMarker.type}</li>
                                                <li>{selectedMarker.type2}</li>
                                            </ul>
                                            <p>{selectedMarker.content}</p>
                                            {
                                                window?.innerWidth > 767 &&
                                                <div className={'map-button'}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.707"
                                                         height="11.414"
                                                         viewBox="0 0 16.707 11.414">
                                                        <g id="Arrow" transform="translate(-1307.5 -242.793)">
                                                            <line id="Line_12400" data-name="Line 12400" x2="5" y2="5"
                                                                  transform="translate(1318.5 243.5)" fill="none"
                                                                  stroke="#f1eee9"
                                                                  stroke-linecap="round" stroke-width="1"/>
                                                            <line id="Line_12401" data-name="Line 12401" y1="5" x2="5"
                                                                  transform="translate(1318.5 248.5)" fill="none"
                                                                  stroke="#f1eee9"
                                                                  stroke-linecap="round" stroke-width="1"/>
                                                            <line id="Line_12402" data-name="Line 12402" x2="15"
                                                                  transform="translate(1308 248.5)" fill="none"
                                                                  stroke="#f1eee9"
                                                                  stroke-linecap="round" stroke-width="1"/>
                                                        </g>
                                                    </svg>

                                                </div>
                                            }
                                        </div>
                                    </InfoWindow>
                                )}

                            </GoogleMap>
                        </LoadScript>
                    </> :
                    null
            }

            {
                window?.innerWidth > 767 &&
                <LightgalleryProvider
                    lightgallerySettings={
                        {
                            download: false,
                            thumbnail: false,
                            fullScreen: true,
                            share: false
                        }
                    }
                >

                    <div className="google-map-side-gallery">

                        {markers.map((marker) => (
                            <div key={marker.id} data-item={marker.id}
                                 className={`item-single-gallery ${selectedMarkerId === marker.id ? 'active' : ''}`}>
                                <div onClick={handleButtonClickClose} className="close-modal-map">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.414" height="10.414"
                                         viewBox="0 0 10.414 10.414">
                                        <g id="Group_20065" data-name="Group 20065"
                                           transform="translate(-480.793 -6228.793)">
                                            <line id="Line_12384" data-name="Line 12384" x2="9" y2="9"
                                                  transform="translate(481.5 6229.5)" fill="none" stroke="#f9f5f2"
                                                  stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_12385" data-name="Line 12385" x1="9" y2="9"
                                                  transform="translate(481.5 6229.5)" fill="none" stroke="#f9f5f2"
                                                  stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>
                                </div>

                                <div className="content">
                                    <h4>{marker.content}</h4>
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                 viewBox="0 0 30 30">
                                                <g id="Group_22833" data-name="Group 22833" opacity="0.5">
                                                    <path id="Path_10723" data-name="Path 10723"
                                                          d="M120,0V22.969h9.668V17.7h-4.395V5.273h5.332v7.09h7.09V17.7H133.3v5.273h9.668V0Zm12.363,10.605V5.273H137.7v5.332Zm8.848,10.605h-6.152V19.453h4.395V3.516H123.516V19.453h4.395v1.758h-6.152V1.758h19.453Z"
                                                          transform="translate(-112.969)" fill="#f5f5f5"/>
                                                    <path id="Path_10724" data-name="Path 10724"
                                                          d="M141.211,423.758H121.758V422H120v5.273h1.758v-1.758h19.453v1.758h1.758V422h-1.758Z"
                                                          transform="translate(-112.969 -397.273)" fill="#f5f5f5"/>
                                                    <path id="Path_10725" data-name="Path 10725"
                                                          d="M0,1.758H1.758V21.211H0v1.758H5.273V21.211H3.516V1.758H5.273V0H0Z"
                                                          fill="#f5f5f5"/>
                                                </g>
                                            </svg>

                                            <p>Size</p>
                                            <p>{marker.size + ' Sq Ft'}</p>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17.731"
                                                 viewBox="0 0 25 17.731">
                                                <path id="dimensions-03"
                                                      d="M12.511,14.475h8.314a1.617,1.617,0,0,1,1.75,1.75V22.7c0,.17.025.227.217.25a1.592,1.592,0,0,1,1.4,1.615v3c0,.16.035.21.2.207.512,0,.607.1.607.622v1.25c0,.4-.132.542-.525.527-.217,0-.3.037-.287.277.023.41,0,.822,0,1.235,0,.365-.14.5-.5.5H22.66a.417.417,0,0,1-.5-.392c-.1-.467-.2-.935-.282-1.4-.03-.187-.1-.222-.272-.222H3.417c-.212,0-.3.045-.337.27-.065.447-.17.89-.25,1.332-.067.325-.182.417-.517.417H1.33c-.392,0-.522-.135-.522-.535V30.366c0-.14-.015-.2-.185-.2C.087,30.2,0,30.1,0,29.566V28.281c0-.36.145-.517.5-.5.25.015.312-.06.307-.31-.015-.942,0-1.882,0-2.822a1.6,1.6,0,0,1,1.472-1.7c.2-.027.137-.15.137-.25V16.123A1.6,1.6,0,0,1,3.77,14.488a2.125,2.125,0,0,1,.4-.022Zm0,.807H4.172a.829.829,0,0,0-.945.937v6.1c0,.645,0,.637.635.63.15,0,.175-.05.172-.185V21.325a1.6,1.6,0,0,1,1.6-1.6H10.5a1.6,1.6,0,0,1,1.6,1.585v1.032c0,.622,0,.617.627.61.142,0,.185-.035.182-.18V21.3a1.6,1.6,0,0,1,1.57-1.582q2.457-.018,4.912,0A1.6,1.6,0,0,1,20.963,21.3c0,.37,0,.75,0,1.11,0,.537,0,.527.547.54.2,0,.265-.027.265-.25V16.223a.829.829,0,0,0-.945-.94Zm-.025,12.5H23.143c.187,0,.25-.037.25-.25v-2.9a.815.815,0,0,0-.895-.885H2.5a.815.815,0,0,0-.885.9v2.872c0,.225.057.267.272.267q5.294-.01,10.611,0Zm0,1.612H23.66c.53,0,.517,0,.537-.527,0-.232-.057-.285-.285-.282H1.362c-.557,0-.545,0-.56.552,0,.212.05.25.25.25l11.446.007ZM8.049,22.942H11.1c.15,0,.2-.027.2-.19V21.367a.807.807,0,0,0-.857-.847H5.7a.81.81,0,0,0-.86.87v1.335c0,.18.045.22.22.217C6.052,22.94,7.052,22.942,8.049,22.942Zm8.867,0h3.047c.14,0,.2-.015.2-.185v-1.41a.8.8,0,0,0-.827-.827h-4.79a.807.807,0,0,0-.837.842q0,.679,0,1.357c0,.172.035.225.215.222C14.919,22.937,15.916,22.942,16.916,22.942ZM2.33,30.191H1.77c-.18,0-.16.057-.16.175v.877c0,.08-.02.192.115.157s.327.117.38-.1c.09-.352.145-.7.225-1.1Zm21.055.63v-.1c0-.55,0-.545-.55-.535-.135,0-.172.03-.14.167.067.292.13.587.177.882.02.125.06.167.19.167.322,0,.322,0,.322-.307Z"
                                                      transform="translate(0 -14.464)" fill="#fff"></path>
                                            </svg>
                                            <p>Bedrooms</p>
                                            <p>{marker.bedroom}</p>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="23.345" height="30"
                                                 viewBox="0 0 23.345 30">
                                                <g id="Group_23409" data-name="Group 23409"
                                                   transform="translate(-56.795)" opacity="0.5">
                                                    <path id="Path_10751" data-name="Path 10751"
                                                          d="M77.926,16.64,76.617,16.4l-.241-1.309-1.728-1.728a5.986,5.986,0,0,0-3.331-6.779A7.277,7.277,0,1,0,58.46,11.906,5.986,5.986,0,0,0,60.9,19.059v7.766L64.073,30l3.175-3.175V23.694l-.755-1.1.755-1.1V19.059A5.993,5.993,0,0,0,68.519,18a6.06,6.06,0,0,0,1.638-.144l5.492,5.492h4.49v-4.49ZM58.553,7.278a5.521,5.521,0,0,1,10.908-1.2,5.976,5.976,0,0,0-4.877,1.716q-.106.106-.208.219Q64.225,8,64.073,8a5.976,5.976,0,0,0-4.66,2.233,5.487,5.487,0,0,1-.86-2.956Zm7.421,10.483L65.49,18v2.951L64.358,22.6l1.132,1.643V26.1l-1.418,1.418L62.655,26.1V18l-.483-.244a4.23,4.23,0,0,1-2.262-4.516A7.236,7.236,0,0,0,63.194,14.5v.991h1.758V11.859H63.194v.868a5.5,5.5,0,0,1-2.588-1.158,4.226,4.226,0,1,1,5.368,6.191Zm12.409,3.826h-2l-5.722-5.722-.514.169a4.2,4.2,0,0,1-.49.129A6,6,0,0,0,66.471,8.5a4.228,4.228,0,0,1,6.359,4.844l-.169.514,2.086,2.086.361,1.962,1.962.361,1.312,1.312v2Z"
                                                          fill="#f5f5f5"/>
                                                </g>
                                            </svg>
                                            <p>Handover Date</p>
                                            <p>{marker.handover}</p>
                                        </li>
                                    </ul>
                                    <div className="gallery">
                                        <ul>
                                            {
                                                marker.image?.length > 0 &&
                                                marker.image?.filter(f => f.icon === 'on')?.map((e, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <LightgalleryItem group={'any'} src={e?.full_path}>
                                                                <div className="img-wrapper">
                                                                    <Img src={e?.full_path}/>
                                                                </div>
                                                            </LightgalleryItem>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <Button color={'#F1EEE9'} icon_border={'#F1EEE9'} src={`/project/${marker.slug}`}
                                            margin={'40px 0 0'}
                                            text={'LEARN MORE'}/>

                                </div>
                            </div>

                        ))}
                    </div>

                </LightgalleryProvider>
            }
        </StyledComponent>

    );
};

const StyledComponent = styled.section`
  height: 70vh;
  position: relative;
  overflow: hidden;

  .map-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .dc-btn {
      position: relative;
      z-index: 3;
    }

    &:after {
      content: '';
      background-image: url('/images/static/maploader.jpg');
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
    }

    &:before {
      content: '';
      background-color: rgba(0, 0, 0, 0.73);
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      z-index: 2;
    }
  }

  .map-button {
    cursor: pointer;

    svg {
      transform: translateX(0);
      transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);
    }

    &:hover {
      svg {
        transform: translateX(10px);
      }
    }
  }

  .google-map-side-gallery {
    .item-single-gallery {
      background: ${hover};
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      position: absolute;
      padding: 40px;
      transform: translateX(100%);
      min-width: 468px;
      transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);

      .close-modal-map {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }

      .content {
        height: calc(70vh - 60px);
        overflow-x: scroll;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }


        h4 {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          color: #F1EEE9;
          text-transform: uppercase;
          width: 60%;
          margin-bottom: 40px;
        }

        ul {
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;

          li {
            margin-bottom: 10px;
            margin-bottom: 10px;
            display: inline-flex;

            svg {
              height: 20px;
              width: 20px;
              margin-right: 20px;
            }

            p {
              font-size: 12px;
              line-height: 24px;
              color: #F1EEE9;
              text-transform: uppercase;
              margin: 0;
              width: 30%;

              &:last-child {

              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .gallery {
        ul {
          margin: 0;
          gap: 15px;
          flex-wrap: wrap;
          flex-direction: unset;

          li {
            flex: 0 0 calc(50% - 15px);
            margin: 0;

            .react_lightgallery_item {
              width: 100%;
              cursor: pointer;
            }

            .img-wrapper {
              padding-top: calc(100 / 160 * 100%);
              position: relative;
              min-height: 100px;
            }
          }
        }
      }

      &.active {
        transform: translateX(0);
      }


    }
  }


  .poi-info-window div, .poi-info-window a {
    background: transparent;
    color: #F1EEE9;

  }
`;
export default React.memo(GoogleMapsMarkers)