export const ApiParam = {
    type: 'type',
    value: 'value',
    get_section: 'get_section',
    image: 'image',
    post: 'post',
    file: 'file',
    gallery: 'gallery',
    category: 'category',
    location: 'location',
    size: 'size',
    area: 'area',
    projectId: 'project_id',
    status: 'status',
    token: 'token',
    id: "id",
};
