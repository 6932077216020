import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Text from "../../components/project/Text";
import AtGlance from "../../components/project/AtGlance";
import Video from "../../components/project/Video";
import LocationMap from "../../components/project/LocationMap";
import Form from "../../components/project/Form";
import DetailBanner from "../../components/project/DetailBanner";
import Timeline from "../../components/project/Timeline";
import Amenities from "../../components/project/Amenities";
import FloorPlan from "../../components/project/FloorPlan";
import Gallery from "../../components/project/Gallery";
import RelatedProjects from "../../components/project/RelatedProjects";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchData, fetchDetail} from "../../api/redux/projects";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/animations/PageTransition";

const MyComponent = () => {
    const dispatch = useDispatch()
    let getPost = useSelector(state => state.projectReducer)
    const endPoints = apiEndPoints.PROJECT_DETAIL;
    const location = useLocation()

    const params = {
        [ApiParam.type]: 'slug',
        [ApiParam.value]: location.pathname.replace('/land/', ''),
        [ApiParam.location]: '',
        [ApiParam.image]: 'yes',
        [ApiParam.post]: 'yes',
    }

    useEffect(() => {
        dispatch(fetchDetail([endPoints, params]))
    }, [])


    const Detail = getPost?.detail?.data;


    // section data refactor
    const atGlance = Detail?.posts?.list?.find(f => f.data?.slug === "at-a-glance")
    const gallery = Detail?.posts?.list?.find(f => f.data?.slug === "gallery")
    const map = Detail?.posts?.list?.find(f => f.data?.slug === "map")

    // console.log('Detail', Detail?.posts?.list)

    return (
        <HelmetProvider>
            {/*<motion.div key={`182aa3`} className="page-loader" exit="exit" animate="anim"*/}
            {/*            style={{height: `${document.body.clientHeight}px`}}*/}
            {/*            variants={PageAnimation}*/}
            {/*            initial="init">*/}
            {/*</motion.div>*/}
            <Helmet>
                <meta charSet="utf-8"/>
                {Detail?.product_data?.title ?
                    <title> {Detail && Detail?.product_data?.title} | Sheltech Pvt Ltd </title> :
                    <title> Sheltech Pvt Ltd </title>}

                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent className={'project-single land-single'}>
                <DetailBanner address={Detail?.product_data?.location}
                              subTitle={Detail?.product_data?.type_title}
                              img={Detail?.images?.list?.[0]?.full_path}
                              title={Detail?.product_data?.title}/>

                <AtGlance data={atGlance}/>
                <Gallery data={gallery}/>
                <LocationMap data={map}/>
                <Form/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  .video-modal {
    padding-top: 100px;
  }

  //section {
  //  overflow: hidden;
  //  margin-bottom: -2px;
  //}

  .detail-banner__menu {
    display: none;
  }

  .detail-banner {
    .container {
      h6 {
        display: none;
      }
    }
  }

  .at-glance {
    //padding-top: 200px;
    //padding-bottom: 0 !important;

    .at-glance__img {
      padding-top: 100%;
    }

    .address__single img {
      filter: none;
    }

    @media (max-width: 767px) {
      padding-top: 120px;
    }
  }

  .gallery {
    padding-bottom: 200px;
  }
`;

export default MyComponent;
