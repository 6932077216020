import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {whites} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data, id}) => {

    return (
        <StyledComponent className={'texts-section pt-200 pb-200'} id={id}>
            <Container>
                <Row>
                    <Col md={{span: 10, offset: 1}} sm={12} className={'text-center'}>
                        <img src="/images/static/flower.png" alt="" className="shadow-right"/>
                        <img src="/images/static/flower-v2.png" alt="" className="shadow-left"/>
                        <Title center text={data?.data?.subtitle}/>
                        {reactHtmlParser(data?.data?.description)}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F5F5F5;
  overflow: hidden;

  .container {
    position: relative;
  }

  .shadow-right {
    position: absolute;
    right: -50px;
    top: -50px;
  }

  .shadow-left {
    position: absolute;
    left: -150px;
    bottom: 10px;
  }

  p {
    @media (min-width: 991px) {
      width: 80%;
      margin: auto;
    }

    &:nth-last-child(1) {
      margin-top: 20px;
    }
  }

  @media (max-width: 991px) {
    .shadow-right {
      height: 80px;
    }

    .shadow-left {
      height: 80px;
    }
  }

  @media (max-width: 767px) {
    .shadow-right {
      height: 50px;
      right: 0;
    }

    .shadow-left {
      height: 40px;
      left: 0;
      bottom: -40px;
    }
  }
`;

export default MyComponent;
