import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Title from "../Title";
import { hover, text, whites } from "../../styles/globalStyleVars";
import { Img } from "../Img";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import SliderNextWhite from "../SliderNextWhite";
import SliderPrevWhite from "../SliderPrevWhite";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import { cleanData, addToFav } from "../../api/redux/user";
import { ApiParam } from "../../api/network/apiParams";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({ data }) => {
  const [offset, setOffset] = useState(0);
  const [itemHeight, setItemHeight] = useState(0);
  useLayoutEffect(() => {
    // offset
    setOffset(document.querySelector(".container")?.offsetLeft + 15);
    window.addEventListener("resize", () => {
      setOffset(document.querySelector(".container")?.offsetLeft + 15);
    });

    // set section height
    if (window.innerWidth > 991 && data?.length > 0) {
      setTimeout(() => {
        const getHeight =
          document.querySelector(".feature-project").clientHeight;
        document.querySelector(".feature-project").style.height =
          getHeight + "px";
        window.addEventListener("resize", () => {
          const getHeight =
            document.querySelector(".feature-project").clientHeight;
          document.querySelector(".feature-project").style.height =
            getHeight + "px";
        });

        document.querySelector(".feature-project__left").style.height =
          document.querySelector(".feature-project__right .swiper-slide-active")
            ?.clientHeight + "px";
      }, 1000);
    }
  }, [data]);

  const location = useLocation();
  const store = useSelector((store) => store.userReducer);
  const history = useHistory();

  // form submission
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
    watch,
  } = useForm({
    mode: "all",
  });
  const success = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      closeOnClick: true,
      progress: undefined,
    });

  const error = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      closeOnClick: true,
      progress: undefined,
    });

  const dispatch = useDispatch();
  const apiEndPoint = apiEndPoints.FAVOURITE;

  const handleFav = (id, status) => {
    const params = {
      [ApiParam.projectId]: id,
      [ApiParam.status]: status,
      [ApiParam.token]: localStorage.getItem("sheltechuser"),
    };

    var formData = new FormData();
    // formData.append('form_id', 'visitor-login');
    formData.append("project_id", id);
    formData.append("status", status);
    formData.append("token", localStorage.getItem("sheltechuser"));
    dispatch(addToFav([apiEndPoint, formData]));
  };

  return (
    <StyledComponent
      offset={offset}
      className={"feature-project pt-200 pb-80 no-line"}
    >
      <Container
        className={"pr-0"}
        fluid
        style={{ paddingLeft: offset + "px" }}
      >
        <Row>
          <Col sm={4} className={"feature-project__left"}>
            <div className="feature-project__left__slier">
              {data && data?.length > 0 && (
                <Swiper
                  loop={true}
                  spaceBetween={30}
                  slidesPerView={1}
                  allowSlideNext={true}
                  allowSlidePrev={true}
                  allowTouchMove={false}
                  speed={500}
                  effect={"fade"}
                  fadeEffect={{
                    crossFade: true,
                  }}
                  // pagination={{
                  //     type: "fraction",
                  // }}
                  navigation={{
                    prevEl: ".feature-project .slider_prev",
                    nextEl: ".feature-project .slider_next",
                  }}
                  modules={[Navigation, EffectFade, Autoplay]}
                  // autoplay={false}

                  autoplay={{
                    delay: 5300,
                    disableOnInteraction: false,
                  }}
                >
                  {data?.length > 0 &&
                    data.map((i) => (
                      <SwiperSlide key={i?.product_data?.id}>
                        <Title text={i?.product_data?.subtitle} />
                        <h4>{reactHtmlParser(i?.product_data?.title)}</h4>
                        <p>
                          {i?.product_data?.type_title} <span />{" "}
                          {i?.product_data?.location}
                        </p>
                      </SwiperSlide>
                    ))}
                </Swiper>
              )}
            </div>

            {/*nav*/}
            <ul className={"nav"}>
              <li className={"slider_prev"}>
                <SliderPrevWhite
                  borderColor={hover}
                  hoverBackground={hover}
                  hoverColor={"#FFF"}
                  color={hover}
                />
              </li>
              <li className={"slider_next"}>
                <SliderNextWhite
                  borderColor={hover}
                  hoverBackground={hover}
                  hoverColor={"#FFF"}
                  color={hover}
                />
              </li>
            </ul>
          </Col>
          <Col sm={8} className={"feature-project__right"}>
            {data && data?.length > 0 && (
              <Swiper
                loop={true}
                spaceBetween={30}
                slidesPerView={1}
                allowSlideNext={true}
                allowSlidePrev={true}
                allowTouchMove={false}
                speed={900}
                // pagination={{
                //     type: "fraction",
                // }}
                navigation={{
                  prevEl: ".slider_prev",
                  nextEl: ".slider_next ",
                }}
                modules={[Navigation, Autoplay]}
                // autoplay={false}

                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  500: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
              >
                {data?.length > 0 &&
                  data?.map((i) => (
                    <SwiperSlide key={i?.product_data?.id}>
                      <div className="feature-project__slider">
                        <Link to={`/project/${i?.product_data?.slug}`} />
                        <div className="feature-project__slider__img">
                          <Img
                            src={
                              i?.images?.list?.find((f) => f?.thumb === "on")
                                ?.full_path
                            }
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
          </Col>
        </Row>
      </Container>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(50% - 80px);
    background-color: ${hover};
  }

  .feature-project__left {
    position: relative;
    z-index: 2;

    .title {
      margin: 0 0 20px 0;
    }

    h4 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 300;
    }

    p {
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 20px;
      font-weight: bold;

      span {
        height: 4px;
        width: 4px;
        border-radius: 50%;
        //background-color: ${hover};
        background-color: ${text};
        margin: 0 8px;
        display: inline-block;
      }
    }

    //.swiper-slide {
    //  background-color: #fff;
    //}

    .nav {
      display: flex;
      margin-top: 20px;

      li {
      }
    }
  }

  .feature-project__right {
    .feature-project__slider {
      position: relative;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
      }

      .add-to-fav {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        z-index: 2;

        svg {
          circle,
          path {
            transition: 0.6s ease;
          }
        }

        &:hover {
          svg {
            #Hover {
              cy: -13px;
            }

            #Path_1920 {
              stroke: ${text};
            }
          }
        }
      }

      &__img {
        padding-top: calc(350 / 270 * 100%);
        position: relative;
        overflow: hidden;

        img {
          transform: scale(1.01);
        }
      }

      &:hover {
        .feature-project__slider__img {
          img {
            transform: scale(1.04);
          }
        }
      }
    }

    .swiper-slide {
      transition: all 0.6s ease;
    }

    @media (min-width: 991px) {
      .swiper-slide-active {
        width: 50% !important;
      }
    }
  }

  @media (max-width: 991px) {
    &:after {
      height: 30%;
    }

    .feature-project__left {
      min-width: 100%;
      margin-bottom: 15px;

      /* h2,
      h4,
      p {
        color: #ffffff;
      } */

      .nav {
        position: relative;
        inset: unset;
        margin-bottom: 15px;
      }

      .swiper-slide {
        /* background-color: ${hover}; */
      }
    }

    .col-sm-8 {
      min-width: 100%;
    }

    .container-fluid {
      padding-right: ${(p) => p.offset}px !important;
    }
  }

  @media (max-width: 767px) {
    .container-fluid {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .feature-project__right .swiper-slide.feature-project__slider__img {
      padding-top: 120%;
    }
    padding-bottom: 0px !important;
  }
`;

export default MyComponent;
