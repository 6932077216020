import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {text, title} from "../styles/globalStyleVars";
import Title from "../components/Title";
import FeatureProject from "../components/home/FeatureProject";
import Button from "../components/Button";
import Video from "../components/home/Video";
import Sapphire from "../components/home/Sapphire";
import Excellence from "../components/home/Excellence";
import Testimonial from "../components/Testimonial";
import Insigths from "../components/home/Insigths";
import TextImage from "../components/TextImage";
import MissionVision from "../components/about/MissionVision";
import WhatMakeBest from "../components/about/WhatMakeBest";
import Team from "../components/about/Team";
import ManagementTeam from "../components/about/ManagementTeam";
import AssociateCompanies from "../components/about/AssociateCompanies";
import AwardSlider from "../components/about/AwardSlider";
import InnerBanner from "../components/InnerBanner";
import CSR from "../components/about/CSR";
import Counter from "../components/about/Counter";
import Founder from "../components/about/Founder";
import Jobs from "../components/career/Jobs";
import LandSlider from "../components/landowner/LandSlider";
import Form from "../components/landowner/Form";
import MediaList from "../components/MediaCenter/MediaList";
import Text from "../components/career/Text";
import Address from "../components/contact/Address";

const MyComponent = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Title</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={'a legacy of excellence'} subTitle={'About us'}
                             img={'/images/dynamic/about-banner.jpg'}/>

                <FeatureProject/>
                <Video/>
                <Sapphire/>
                <Excellence/>
                <Testimonial/>
                <Insigths/>
                <TextImage/>
                <MissionVision/>
                <WhatMakeBest/>
                <Counter/>
                <Founder/>
                <Team/>
                <ManagementTeam/>
                <AssociateCompanies/>
                <AwardSlider/>
                <CSR/>
                <Text/>
                <Jobs/>
                <Address/>
                <Form/>
                <LandSlider/>
                <Form/>
                <Testimonial/>
                <Team/>
                <MediaList/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  h3 {
    font-family: ${title};
    text-transform: uppercase;
    font-size: 40px;
  }

  .map {
    background-color: ${text};

    img {
      width: 100%;
    }
  }
`;

export default MyComponent;
