import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Col, Container, Row} from "react-bootstrap";
import InnerBanner from "../../components/InnerBanner";
import Title from "../../components/Title";

const MyComponent = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Privacy Policy | Sheltech Pvt Ltd</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                {/*<InnerBanner title={"Privacy Policy for <br/> Sheltech (Pvt.) Ltd."}/>*/}
                <Container className={'pt-150 pb-150'}>

                    <Row>
                        <Col sm={12}>
                            <Title text={"Privacy Policy for  Sheltech (Pvt.) Ltd."}/>
                        </Col>
                        <Col sm={10}>

                            <p>At Sheltech, accessible at www.sheltech-bd.com, one of our main priorities is the privacy
                                of our visitors. This Privacy Policy document contains the types of information that is
                                collected and recorded by Sheltech and how we use it.</p>
                            <p>If you have additional questions or require more information about our privacy policy, do
                                not hesitate to contact us. This privacy policy applies only to our online activities
                                and is valid for visitors to our website with regard to the information that they share
                                and/or collect at Sheltech. This policy is not applicable to any information collected
                                offline or via channels other than this website.
                            </p>
                            <h4>Consent</h4>
                            <p>By using our website, you hereby consent to our privacy policy and agree to its
                                terms.</p>
                            <h4>Information we collect
                            </h4>
                            <p>The personal information that you are asked to provide and the reasons why you are asked
                                to provide it will be made clear to you at the point where we ask you to provide your
                                personal information.</p>
                            <p>If you contact us directly, we may receive additional information about you, such as your
                                name, email address, phone number, the contents of the message and/or attachments you
                                may send us, and any other information you may choose to provide.</p>
                            <p>When you register for an account, we may ask for your contact information, including
                                items such as your name, company name, address, email address, and telephone number.</p>
                            <h4>How do we use your information?</h4>
                            <p>We use the information we collect in various ways, including:</p>
                            <p>Provide, operate, and maintain our website </p>
                            <p>Improve, personalize, and expand our website</p>

                            <p> Understand and analyze how you use our website</p>

                            <p> Develop new products, services, features, and functionality</p>
                            <p>Communicate with you, either directly or through one of our partners, including for
                                customer service, to provide you with updates and other information relating to the
                                website, and for marketing and promotional purposes</p>
                            <p>Send you emails </p>
                            <p>Find and prevent fraud </p>
                            <h4>Log Files</h4>
                            <p>Sheltech follows a standard procedure for using log files. These files log visitors when
                                they visit websites. All hosting companies do this as part of their hosting services'
                                analytics. The information collected by log files includes internet protocol (IP)
                                addresses, browser type, Internet Service Provider (ISP), date and time stamp,
                                referring/exit pages, and possibly the number of clicks. These are not linked to any
                                information that is personally identifiable. The purpose of the information is to
                                analyze trends, administer the site, track users' movements on the website, and gather
                                demographic information.
                            </p>
                            <h4>Cookies and Web Beacons</h4>
                            <p>Like any other website, Sheltech uses "cookies." These cookies are used to store
                                information, including visitors' preferences and the pages on the website that the
                                visitor accessed or visited. The information is used to optimize the users' experience
                                by customizing our web page content based on visitors' browser type and/or other
                                information.</p>
                            <h4>Our Advertising Partners</h4>
                            <p>Some of the advertisers on our site may use cookies and web beacons. Our advertising
                                partners are listed below. Each of our advertising partners has its own privacy policy
                                for their policies on user data. For easier access, we hyperlinked to their privacy
                                policies below.</p>
                            <p>Google (https://policies.google.com/technologies/ads)</p>
                            <h4>Advertising Partners Privacy Policies</h4>
                            <p>You may consult this list to find the privacy policy for each of the advertising partners
                                at Sheltech. Third-party ad servers or ad networks use technologies like cookies,
                                JavaScript, or Web beacons that are used in their respective advertisements and links
                                that appear on Sheltech, which are sent directly to users' browsers. They automatically
                                receive your IP address when this occurs. These technologies are used to measure the
                                effectiveness of their advertising campaigns and/or to personalize the advertising
                                content that you see on websites that you visit.</p>
                            <p>Note that Sheltech has no access to or control over these cookies that are used by
                                third-party advertisers.</p>
                            <h4>Third-Party Privacy Policies</h4>
                            <p>Sheltech's Privacy Policy does not apply to other advertisers or websites. Thus, we are
                                advising you to consult the respective privacy policies of these third-party ad servers
                                for more detailed information. It may include their practices and instructions about how
                                to opt out of certain options.</p>
                            <p>You can choose to disable cookies through your individual browser options. More detailed
                                information about cookie management with specific web browsers can be found on the
                                browsers' respective websites.</p>
                            <h4>CCPA Privacy Rights (Do Not Sell My Personal Information)</h4>
                            <p>Under the CCPA, among other rights, California consumers have the right to:</p>

                            <p>Request that a business that collects a consumer's personal data disclose the categories
                                and specific pieces of personal data that a business has collected about consumers.</p>

                            <p>Request that a business delete any personal data about the consumer that it has
                                collected.</p>

                            <p>Request that a business that sells a consumer's personal data not sell the consumer's
                                personal data.</p>

                            <p>If you make a request, we have one month to respond to you. If you would like to exercise
                                any of these rights, please contact us.</p>
                            <h4>GDPR Data Protection Rights</h4>
                            <p>We would like to make sure you are fully aware of all of your data protection rights.
                                Every user is entitled to the following:</p>

                            <p>The right to access You have the right to request copies of your personal data. We may
                                charge you a small fee for this service.</p>

                            <p>The right to rectification: You have the right to request that we correct any information
                                you believe is inaccurate. You also have the right to request that we complete the
                                information you believe is incomplete.</p>

                            <p>The right to erasure: You have the right to request that we erase your personal data
                                under certain conditions.</p>

                            <p>The right to restrict processing: You have the right to request that we restrict the
                                processing of your personal data under certain conditions.</p>

                            <p>The right to object to processing: You have the right to object to our processing of your
                                personal data under certain conditions.</p>

                            <p>The right to data portability: You have the right to request that we transfer the data
                                that we have collected to another organization or directly to you under certain
                                conditions.</p>

                            <p>If you make a request, we have one month to respond to you. If you would like to exercise
                                any of these rights, please contact us.</p>

                            <h4>Children's Information
                            </h4>
                            <p> Another part of our priority is adding protection for children while using the internet.
                                We encourage parents and guardians to observe, participate in, and/or monitor and guide
                                their online activity.</p>

                            <p> Sheltech does not knowingly collect any personally identifiable information from
                                children under the age of 13. If you think that your child provided this kind of
                                information on our website, we strongly encourage you to contact us immediately, and we
                                will do our best efforts to promptly remove such information from our records.</p>
                            <h4>Changes to This Privacy Policy</h4>
                            <p>We may update our privacy policy from time to time. Thus, we advise you to review this
                                page periodically for any changes. We will notify you of any changes by posting the new
                                privacy policy on this page. These changes are effective immediately after they are
                                posted on this page.</p>
                            <h4>Contact Us
                            </h4>
                            <p>If you have any questions or suggestions about our privacy policy, do not hesitate to
                                contact us.</p>

                        </Col>
                    </Row>
                </Container>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  h4 {
    font-size: 30px;
    margin: 30px 0 10px 0;
  }

  p {
    margin-bottom: 15px;
  }
`;

export default MyComponent;
