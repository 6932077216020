import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import ProjectList from "../../components/project/ProjectList";
import InnerBanner from "../../components/InnerBanner";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import { ApiParam } from "../../api/network/apiParams";
import { fetchData } from "../../api/redux/projects";
import { motion } from "framer-motion";
import { PageAnimation } from "../../components/animations/PageTransition";

const MyComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let getPost = useSelector((state) => state.projectReducer);

  // api config
  const endPoints = apiEndPoints.PROJECT;

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  // console.log("query", query.get("type"));

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Projects | Sheltech Pvt Ltd</title>
      </Helmet>

      <StyledComponent className={"project-page"}>
        <InnerBanner
          img={
            query.get("type") === "commercial"
              ? "/images/dynamic/commercial-banner.jpeg"
              : getPost?.data?.project_data?.page_images?.list?.[0]?.full_path
          }
          subTitle={getPost?.data?.project_data?.page_data?.subtitle}
          title={getPost?.data?.project_data?.page_data?.short_desc}
        />
        <ProjectList data={getPost} />
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
  @media (max-width: 600px) {
    .InnerBanner {
      padding-top: calc(100%);
    }
  }

  //section {
  //  overflow: hidden;
  //  margin-bottom: -2px;
  //}
`;

export default MyComponent;
