import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get, post} from "../../network/axiosServices";


const initialState = {
    loading: false,
    data: [],
    error: '',
    formLoading: false
}

export const signUp = createAsyncThunk("signup", (params) => {
    return post(params);
});

export const signIn = createAsyncThunk("signin", (params) => {
    return post(params);
});

export const addToFav = createAsyncThunk("add-to-favourite", (params) => {
    return post(params);
});


const postSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        cleanData: (state) => {
            state.formLoading = false
            state.data.status = ""
            state.data.message = ""
            state.error = ''
        }
    },
    extraReducers: builder => {
        builder.addCase(signUp.pending, (state) => {
            state.formLoading = true
            state.data = []
            state.error = ''
        })
        builder.addCase(signUp.fulfilled, (state, action) => {
            state.formLoading = false
            state.data = action.payload
            state.error = ''
        })
        builder.addCase(signUp.rejected, (state, action) => {
            state.formLoading = false
            state.data = []
            state.error = action.error
        })
        builder.addCase(signIn.pending, (state) => {
            state.formLoading = true
            state.data = []
            state.error = ''
        })
        builder.addCase(signIn.fulfilled, (state, action) => {
            state.formLoading = false
            state.data = action.payload
            state.error = ''
        })
        builder.addCase(signIn.rejected, (state, action) => {
            state.formLoading = false
            state.data = []
            state.error = action.error
        })
        builder.addCase(addToFav.pending, (state) => {
            state.formLoading = true
            state.data = []
            state.error = ''
        })
        builder.addCase(addToFav.fulfilled, (state, action) => {
            state.formLoading = false
            state.data = action.payload
            state.error = ''
        })
        builder.addCase(addToFav.rejected, (state, action) => {
            state.formLoading = false
            state.data = []
            state.error = action.error
        })
    }
})


export default postSlice.reducer;
export const {cleanData} = postSlice.actions
