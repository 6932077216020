import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get, post} from "../../network/axiosServices";


const initialState = {
    loading: false,
    formLoading: false,
    data: [],
    land: [],
    error: '',
    detail: [],
    formData: [],
    detailLoading: false,
    detailError: '',
    fetchSapphireData: [],
    favData: [],
    favourite: []
}

export const fetchData = createAsyncThunk("fetchProject", (params) => {
    return get(params);
});

export const fetchSapphireData = createAsyncThunk("fetchSapphireProject", (params) => {
    return get(params);
});

export const fetchDetail = createAsyncThunk("fetchDataDetailProject", (params) => {
    return get(params);
});

export const postFav = createAsyncThunk("projectFavForm", (params) => {
    return post(params);
});

export const getFav = createAsyncThunk("projectFav", (params) => {
    return get(params);
});

export const postForm = createAsyncThunk("projectForm", (params) => {
    return post(params);
});

export const fetchLand = createAsyncThunk("fetchLandProject", (params) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'project',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchData.pending, (state) => {
            state.loading = true
            state.data = []
            state.error = ''
        })
        builder.addCase(fetchData.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
        })
        builder.addCase(fetchData.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error
        })
        builder.addCase(fetchDetail.pending, (state, action) => {
            state.detailLoading = true
            state.detail = []
            state.detailError = ''
        })
        builder.addCase(fetchDetail.fulfilled, (state, action) => {
            state.detailLoading = false
            state.detail = action.payload
            state.detailError = ''
        })
        builder.addCase(fetchDetail.rejected, (state, action) => {
            state.detailLoading = false
            state.detail = []
            state.detailError = action.error
        })
        builder.addCase(postForm.pending, (state, action) => {
            state.formLoading = true
            state.formData = []
            state.error = ''
        })
        builder.addCase(postForm.fulfilled, (state, action) => {
            state.formLoading = false
            state.formData = action.payload
            state.error = ''
        })
        builder.addCase(postForm.rejected, (state, action) => {
            state.formLoading = false
            state.formData = []
            state.error = action.error
        })
        builder.addCase(postFav.pending, (state, action) => {
            state.formLoading = true
            state.favData = []
            state.error = ''
        })
        builder.addCase(postFav.fulfilled, (state, action) => {
            state.formLoading = false
            state.favData = action.payload
            state.error = ''
        })
        builder.addCase(postFav.rejected, (state, action) => {
            state.formLoading = false
            state.favData = []
            state.error = action.error
        })
        builder.addCase(fetchLand.pending, (state, action) => {
            state.loading = true
            state.land = []
            state.error = ''
        })
        builder.addCase(fetchLand.fulfilled, (state, action) => {
            state.loading = false
            state.land = action.payload
            state.error = ''
        })
        builder.addCase(fetchLand.rejected, (state, action) => {
            state.loading = false
            state.land = []
            state.error = action.error
        })
        builder.addCase(fetchSapphireData.pending, (state, action) => {
            state.loading = true
            state.fetchSapphireData = []
            state.error = ''
        })
        builder.addCase(fetchSapphireData.fulfilled, (state, action) => {
            state.loading = false
            state.fetchSapphireData = action.payload
            state.error = ''
        })
        builder.addCase(fetchSapphireData.rejected, (state, action) => {
            state.loading = false
            state.fetchSapphireData = []
            state.error = action.error
        })
        builder.addCase(getFav.pending, (state, action) => {
            state.loading = true
            state.favourite = []
            state.error = ''
        })
        builder.addCase(getFav.fulfilled, (state, action) => {
            state.loading = false
            state.favourite = action.payload
            state.error = ''
        })
        builder.addCase(getFav.rejected, (state, action) => {
            state.loading = false
            state.favourite = []
            state.error = action.error
        })
    }
})


export default postSlice.reducer
