import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hover, title, whites} from "../../styles/globalStyleVars";
import Title from "../Title";
import Select, {components} from "react-select";
import Button from "../Button";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {PostForm} from "../../api/redux/landowner";

const MyComponent = () => {
    const store = useSelector(store => store)

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? '#2B3382' : '#FFF',
            margin: 0,
            cursor: 'pointer',
            ":hover": {
                backgroundColor: "#2B3382",
                color: '#FFF'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,

        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer'
        }),

    };

    const facing = [
        {value: 'north', label: 'North '},
        {value: 'south', label: 'South '},
        {value: 'east', label: 'East '},
        {value: 'west', label: 'West '},

    ];

    const optionsOne = [
        {value: 'residential', label: 'Residential '},
        {value: 'commercial', label: 'Commercial '},
    ];

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ? <img src={'/images/static/caret-white-v2.svg'} alt=""/> :
                        <img src={'/images/static/caret-white.svg'} alt=""/>}
                </components.DropdownIndicator>
            )
        );
    };

    const [Facing, setFacing] = useState('')
    const [landCat, setlandCat] = useState('')
    // const [Facing,setFacing] = useState('')
    const handleFacing = (e) => {
        setFacing(e)
    }
    const handleCat = (e) => {
        setlandCat(e)
    }


    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    const apiEndPoint = apiEndPoints.POST_FORM

    const [landCatValidate, setLandCatValidate] = useState(false)

    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append('form_id', 'landowner-form');
        formData.append('locality', e?.locality);
        formData.append('size_of_land', e?.size);
        formData.append('Width_of_the_road_in_front', e?.road);
        formData.append('facing', Facing);
        formData.append('category_of_land', landCat);
        formData.append('attractive_feature', e?.feature);
        formData.append('contact_person', e?.contactPerson);
        formData.append('address', e?.address);
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.number);

        dispatch(PostForm([apiEndPoint, formData]))

    }

    useEffect(() => {

        if (!store?.landReducer?.formLoading && isSubmitSuccessful) {
            success('Thank you for your message')
            reset()
        }
    }, [isSubmitSuccessful, store])


    // useEffect(() => {
    //     console.log('landCatValidate', landCatValidate)
    // }, [landCatValidate])

    return (
        <StyledComponent className={'land-form pt-150 pb-150'}>
            <Container>

                <Form>
                    <Row className={'land-form__input'}>

                        <Col md={6} sm={12}>
                            <h4>Customer Information</h4>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('name', {
                                    required: {
                                        value: true,
                                        message: "Please enter your full name",
                                    }
                                })} type="text" placeholder="Name*"/>
                                <p className={'form-error'}>{errors.name?.message}</p>
                            </Form.Group>

                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('address')} type="text" placeholder="Address"/>
                                <p className={'form-error'}>{errors.address?.message}</p>
                            </Form.Group>

                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('email', {
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Please enter a valid email address"
                                    }
                                })} type="email" placeholder="Email"/>
                                <p className={'form-error'}>{errors.email?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('number', {
                                    required: {
                                        value: true,
                                        message: "Please enter a your phone number"
                                    },
                                })} type="number" placeholder="Phone*"/>
                                <p className={'form-error'}>{errors.number?.message}</p>
                            </Form.Group>

                        </Col>

                        <Col md={6} sm={12}>
                            <h4>Requirements</h4>

                            <Form.Group className={'from-group'}>
                                <Select onChange={(e) => handleCat(e.value)} components={{DropdownIndicator}}
                                        styles={customStyles}
                                        classNamePrefix={'custom'} className='select-here files'
                                        placeholder={'Category*'}
                                        options={optionsOne}/>
                                <p className={'form-error'}>{landCatValidate ? "Please select category" : ''}</p>
                            </Form.Group>

                            <Form.Group className={'from-group'}>
                                <Form.Control  {...register('locality', {
                                    required: {
                                        value: true,
                                        message: "Please enter Locality",
                                    }
                                })} type="text" placeholder="Location*"/>
                                <p className={'form-error'}>{errors.locality?.message}</p>
                            </Form.Group>

                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('size')} type="text" placeholder="Size(sft)"/>
                            </Form.Group>

                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('message')} type="text" placeholder="Message"/>
                            </Form.Group>

                            <div onClick={handleSubmit(handleFormSubmit)}>
                                <Button color={'#212158'} borderColor={"#212158"} hoverColor={"#FFF"}
                                        hoverBackground={"#212158"}
                                        margin={'35px 0 0px 0'} submitButton
                                        text={'Submit'}/>
                            </div>

                        </Col>
                    </Row>
                </Form>
            </Container>
        </StyledComponent>
    )
};

const StyledComponent = styled.section`
  background-color: #F5F5F5;


  .land-form__input {
    .col-md-6 {
      padding-right: 60px;
    }

    h4 {
      font-size: 24px;
      line-height: 32px;
      color: #212158;
      margin-bottom: 40px;
    }

    form {
      width: 100%;
    }

    .from-group {
      margin-bottom: 25px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }

    .form-control {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #212158;
      padding-left: 0;
      height: 45px;
      //margin-bottom: 25px;
      color: #212158;

      &::placeholder {
        font-size: 15px;
        color: #212158;
      }
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: ${whites} !important;
      border-bottom: 1px solid #F5F5F5;

      &:hover {
        border-bottom: 1px solid #F5F5F5 !important;
      }

      &:focus {
        border-bottom: 1px solid #F5F5F5 !important;
      }
    }
  }

  //caret
  .custom__control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #212158 !important;
    border-radius: 0;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;

    .custom__placeholder, .custom__single-value {
      color: #212158;
      font-size: 15px;
      line-height: 20px;
    }

    .custom__value-container {
      padding-left: 0;
    }

    &--is-focused {

    }
  }

  .css-t3ipsp-control:hover {
    border-color: ${whites};
  }

  .custom__indicator-separator {
    display: none;
  }

  .custom__indicator {
    padding-right: 0;

    img {
      filter: invert(110%) sepia(11%) saturate(7489%) hue-rotate(217deg) brightness(105%) contrast(100%);
    }
  }

  @media (max-width: 1199px) {
    .land-form__title {
      a {
        margin-top: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    .land-form__title h3 {
      font-size: 30px;
      line-height: 36px;
    }

    .land-form__input {
      .col-md-6 {
        padding-right: 15px;

        &:nth-of-type(1) {
          margin-bottom: 50px;
        }
      }
    }
  }

`;

export default MyComponent;
