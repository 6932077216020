import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {whites} from "../../styles/globalStyleVars";
import GoogleMapsComponent from "../project/GoogleMapsComponent"

const MyComponent = ({data, id, lat, long, projectData}) => {


    return (
        <StyledComponent id={id} className={'location-map pt-200'}>
            <Container fluid className={'p-0'}>
                <Row>
                    <Col>
                        <Title center
                               text={data?.data?.subtitle ? data?.data?.subtitle : 'Location Map'}/>
                        {/*<a href="https://maps.app.goo.gl/V1Q8oBQ6netDSqW2A"> <img*/}
                        {/*    src={data?.images?.[0]?.full_path} alt=""/></a>*/}
                        <div className="location-map__wrap">
                            <GoogleMapsComponent data={projectData} lat={projectData?.latitude}
                                                 long={projectData?.longitude}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F5F5F5;

  .location-map__wrap {
    height: 80vh;
    position: relative;
    overflow: hidden;
  }

  a {
    height: 90vh;
    position: relative;
    display: block;
    @media (min-width: 1650px) {
      height: 75vh;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }

  .map-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .dc-btn {
      position: relative;
      z-index: 3;
    }

    &:after {
      content: '';
      background-image: url('/images/static/maploader.jpg');
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
    }

    &:before {
      content: '';
      background-color: rgba(0, 0, 0, 0.73);
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      z-index: 2;
    }
  }
`;

export default MyComponent;
