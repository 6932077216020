import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {ease, hover, text, whites} from "../../styles/globalStyleVars";
import Select, {components} from "react-select";
import Project from "../Project";
import Title from "../Title";

const MyComponent = ({data}) => {

    // dropdown style
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? '#2B3382' : '#]212158',
            margin: 0,
            cursor: 'pointer',
            ":hover": {
                backgroundColor: "#2B3382",
                color: '#FFF'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,

        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer'
        }),

    };

    // options
    const optionsOne = [
        {value: 'ongoing', label: 'Residential '},
        {value: 'completed', label: 'Commercial '},
    ];
    const optionsTwo = [
        {value: 'ongoing', label: 'Ongoing '},
        {value: 'completed', label: 'Completed '},
        {value: 'upcoming', label: 'Upcoming '},
    ];
    const location = [
        {value: 'dhaka', label: 'Dhaka '},
        {value: 'Chittagong', label: 'Chittagong'},
    ];

    const size = [
        {value: '1200', label: '1200 '},
        {value: '1400', label: '1400'},
        {value: '1600', label: '1600'},
    ];

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.732" height="7.003"
                             viewBox="0 0 11.732 7.003">
                            <g id="Group_24047" data-name="Group 24047" transform="translate(18853.867 -10435.998)">
                                <g id="Group_24046" data-name="Group 24046"
                                   transform="translate(-18852.863 10442.258) rotate(-93)">
                                    <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none"
                                          stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_12381" data-name="Line 12381" y1="5" x2="5"
                                          transform="translate(0 5)" fill="none" stroke="#08082b" stroke-linecap="round"
                                          stroke-width="1"/>
                                </g>
                            </g>
                        </svg>

                        :

                        <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
                             viewBox="0 0 11.414 6.414">
                            <g id="Group_22451" data-name="Group 22451" transform="translate(10.707 0.707) rotate(90)">
                                <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none" stroke="#08082b"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_12381" data-name="Line 12381" y1="5" x2="5" transform="translate(0 5)"
                                      fill="none" stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>
                    }

                </components.DropdownIndicator>
            )
        );
    };

    return (
        <StyledComponent className={'project-list pt-200 pb-200'}>
            <Container>

                {/*Project list */}

                <Row className={'project'}>

                    <Col sm={12}>
                        <Title margin={'0 0 60px 0'} text={'Favourites'}/>
                    </Col>

                    {data?.length > 0 && data?.map(i => (
                        <Col key={i?.product_data?.id} md={4} sm={6}>
                            <Project favourite={i?.product_data?.is_favorite} id={i?.product_data?.id}
                                     src={`/project/${i?.product_data?.slug}`}
                                     area={i?.product_data?.area}
                                     category={i?.product_data?.category_title}
                                     title={i?.product_data?.title}
                                     img={i?.images?.list?.[0]?.full_path}/>
                        </Col>
                    ))}

                </Row>


            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`


  .project-list__dis {
    //margin-bottom: 60px;

    ul {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #08082B;
      position: relative;
      margin-bottom: 60px;

      li {
        //height: 57px;
        padding: 20px 60px;
        cursor: pointer;
        position: relative;
        transition: color .5s ease;
        font-weight: 600;

        &:not(:nth-last-child(1)) {
          border-right: 1px solid #08082B;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 0;
          transition: all .8s ${ease};
          background: ${hover};
          z-index: -1;
        }

        &:hover, &.active {
          color: #FFF;

          &:before {
            height: 100%;
          }
        }
      }
    }

  }

  //caret
  .custom__control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${text} !important;
    border-radius: 0;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;
    //margin-bottom: 60px;

    .custom__placeholder, .custom__single-value {
      color: ${text};
      font-size: 15px;
      line-height: 20px;
    }

    .custom__value-container {
      padding-left: 0;
    }

    &--is-focused {

    }
  }

  .custom__menu {
    z-index: 9;
  }

  .css-t3ipsp-control:hover {
    border-color: ${text};
  }

  .custom__indicator-separator {
    display: none;
  }

  .custom__indicator {
    padding-right: 0;
  }

  .project-single {
    margin-bottom: 60px;
  }

  @media (max-width: 767px) {
    .project-single {
      margin-bottom: 40px;
    }

    .select-here {
      margin-bottom: 20px;
    }

    .project-list__dis {
      margin-bottom: 30px;

      ul {
        margin-bottom: 40px;

        li {
          padding: 10px 25px;
          font-size: 14px;
        }
      }
    }
  }
`;

export default MyComponent;
