import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import SliderNextWhite from "../SliderNextWhite";
import SliderPrevWhite from "../SliderPrevWhite";
import Button from "../Button";
import Media from "../Media";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import {whites} from "../../styles/globalStyleVars";


const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'insights pb-200'}>
            <Container>
                <Row>
                    <Col className="insights__top d-flex justify-content-between">

                        <Title text={'insights'}/>
                        <div className="insights__top__nav">
                            <ul>
                                <li className={'slider_prev'}><SliderPrevWhite borderColor={'#08082B'} color={'#08082B'}
                                                                               hoverBackground={'#08082B'}
                                                                               hoverColor={'#FFF'}/></li>
                                <li className={'slider_next'}><SliderNextWhite borderColor={'#08082B'} color={'#08082B'}
                                                                               hoverBackground={'#08082B'}
                                                                               hoverColor={'#FFF'}/></li>
                            </ul>
                            <Button src={'/media-center'} color={'#08082B'} hoverBackground={'#08082B'}
                                    hoverColor={'#FFF'}
                                    borderColor={'#08082B'}
                                    text={'View All'}/>
                        </div>

                    </Col>
                </Row>

                <div className="insights__slider">
                    {data && data?.length > 0 &&
                        <Swiper loop={false}
                                spaceBetween={30}
                                slidesPerView={1}
                                allowSlideNext={true}
                                allowSlidePrev={true}
                                allowTouchMove={true}
                                speed={900}
                            // pagination={{
                            //     type: "fraction",
                            // }}
                                navigation={{
                                    prevEl: '.insights .slider_prev',
                                    nextEl: '.insights .slider_next ',
                                }}
                                modules={[Autoplay, Pagination, Navigation]}

                                autoplay={false}

                            // autoplay={{
                            //     delay: 5000,
                            //     disableOnInteraction: false,
                            // }}
                                breakpoints={{
                                    1600: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },

                                    991: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },

                                    500: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },

                                }}>
                            {data?.length > 0 && data?.map(i => (
                                <SwiperSlide key={i?.page_data?.id}>
                                    <Media link={i?.page_data?.slug}
                                           title={i?.page_data?.title}
                                           img={i?.images?.list?.[0]?.full_path}
                                           date={i?.page_data?.short_desc}
                                           category={i?.page_data?.type}
                                    />
                                </SwiperSlide>
                            ))}


                        </Swiper>
                    }
                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #D6D6D6;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    background-color: ${whites};
  }

  .title {
    margin: 0 !important;
  }

  .insights__top {
    margin-bottom: 80px;

    &__nav {
      display: flex;
      align-items: center;

      ul {
        display: flex;
        margin-right: 60px;
      }
    }
  }

  .insights__slider {
    width: 100%;
  }

  @media (max-width: 767px) {
    .insights__top {
      flex-wrap: wrap;
      margin-bottom: 40px;

      &__nav {
        width: 100%;
        margin-top: 30px;

        ul {
          margin-right: 40px;
        }
      }
    }
  }
`;

export default MyComponent;
