import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "./Title";
import {whites} from "../styles/globalStyleVars";
import {Img} from "./Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'text-image pt-120 pb-120 no-line'}>
            <img className={'icon-shadow-top'} src="/images/static/flower.png" alt=""/>
            <Container>
                <Row>
                    <Col md={6}>
                        <Title text={data?.section_data?.subtitle}/>
                        {reactHtmlParser(data?.section_data?.description)}
                    </Col>

                    <Col sm={6} md={{span: 5, offset: 1}}>
                        <div className="text-image__img">
                            <Img src={data?.images?.list?.[0]?.full_path}/>
                            <img className={'icon-shadow'} src="/images/static/flower.png" alt=""/>
                        </div>

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${whites};
  position: relative;
  overflow: hidden;

  p {
    &:not(:nth-last-child(1)) {
      margin-bottom: 18px;
    }
  }

  .icon-shadow-top {
    left: -100px;
    position: absolute;
    top: 100px;
  }

  .text-image__img {
    position: relative;
    padding-top: calc(560 / 470 * 100%);

    .icon-shadow {
      position: absolute;
      bottom: 30px;
      left: -80px;
    }
  }

  @media (max-width: 767px) {
    .text-image__img {
      margin-top: 40px;
    }
  }
`;

export default MyComponent;
