import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import {Img} from "./Img";
import {hover, text, whites} from "../styles/globalStyleVars";
import {ApiParam} from "../api/network/apiParams";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {postFav} from "../api/redux/projects";
import {toast} from "react-toastify";
import {cleanData, addToFav} from "../api/redux/user";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({title, img, src, coming, sold, sapphire, preBook, area, category, id, favourite}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const store = useSelector(store => store.userReducer)

    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });


    const handleFav = (id, status) => {
        if (localStorage.getItem('sheltechuser')) {
            // console.log('yes handle')
            const endPoints = apiEndPoints.FAVOURITE;
            const formData = new FormData()
            formData.append("id", localStorage.getItem('sheltechuserid'))
            formData.append("project_id", id)
            formData.append("status", status)
            formData.append("token", localStorage.getItem('sheltechuser'))

            dispatch(addToFav([endPoints, formData]))
        } else {
            // console.log('yes not handle')
            history.push('/user/sign-in')
        }

    }

    useEffect(() => {

        // if (!store?.formLoading && store?.data?.status) {
        //     success('Added to favourite')
        //     // reset()
        //     setTimeout(() => {
        //         dispatch(cleanData())
        //         // history.push('/')
        //     }, 300)
        // }
        // if (!store?.formLoading && store?.data?.status === 0) {
        //     error(store?.data?.message)
        //     setTimeout(() => {
        //         dispatch(cleanData())
        //     }, 300)
        // }

    }, [store])

    return (
        <StyledComponent className={'project-single'}>

            {favourite == 0 ?
                <div className="add-to-fav" onClick={() => handleFav(id, 1)}>
                    <svg width="28" height="28"
                         viewBox="0 0 28 28">
                        <defs>
                            <clipPath id="clip-path">
                                <circle id="Mask" cx="14" cy="14" r="14"
                                        transform="translate(1092 18)" fill="none" stroke="#f5f5f5"
                                        stroke-width="1"/>
                            </clipPath>
                        </defs>
                        <g id="Group_23337" data-name="Group 23337"
                           transform="translate(-920 -1066)">
                            <g id="Button_-_Favorite" data-name="Button - Favorite"
                               transform="translate(920 1066)">
                                <g id="Mask_Group_19911" data-name="Mask Group 19911"
                                   transform="translate(-1092 -18)" clip-path="url(#clip-path)">
                                    <g id="Base" transform="translate(1092 18)" fill="none"
                                       stroke="#f5f5f5" stroke-width="1">
                                        <circle cx="14" cy="14" r="14" stroke="none"/>
                                        <circle cx="14" cy="14" r="13.5" fill="none"/>
                                    </g>
                                    <circle id="Hover" cx="14" cy="14" r="14"
                                            transform="translate(1092 46)" fill="#f5f5f5"/>
                                    <path id="Path_1920" data-name="Path 1920"
                                          d="M10.542,0C8.961,0,7.655,1.47,7.126,2.16,6.6,1.47,5.292,0,3.711,0A3.98,3.98,0,0,0,0,4.2,4.383,4.383,0,0,0,1.378,7.438a.328.328,0,0,0,.047.061L6.917,12.98a.293.293,0,0,0,.209.086.288.288,0,0,0,.209-.086l5.676-5.673.058-.056c.047-.045.092-.089.145-.145a.307.307,0,0,0,.053-.07A4.475,4.475,0,0,0,14.253,4.2,3.981,3.981,0,0,0,10.542,0Zm2.23,6.7a.124.124,0,0,0-.022.031c-.028.031-.058.061-.089.089L7.126,12.348,1.912,7.143a.267.267,0,0,0-.07-.089A3.78,3.78,0,0,1,.593,4.192,3.388,3.388,0,0,1,3.711.593c1.7,0,3.151,2.216,3.165,2.238a.307.307,0,0,0,.5,0C7.388,2.809,8.838.593,10.539.593a3.388,3.388,0,0,1,3.118,3.6A3.855,3.855,0,0,1,12.772,6.7Z"
                                          transform="translate(1098.874 25.467)" fill="#f5f5f5"
                                          stroke="#f5f5f5" stroke-width="0.5"/>
                                </g>
                            </g>
                        </g>
                    </svg>

                </div>
                :
                <div className="add-to-fav" onClick={() => handleFav(id, 0)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28"
                         height="28" viewBox="0 0 28 28">
                        <defs>
                            <clipPath id="clip-path">
                                <circle id="Mask" cx="14" cy="14" r="14" transform="translate(1092 18)" fill="none"
                                        stroke="#f5f5f5" stroke-width="1"/>
                            </clipPath>
                        </defs>
                        <g id="Group_24046" data-name="Group 24046" transform="translate(17665 1456)">
                            <g id="Button_-_Favorite" data-name="Button - Favorite" transform="translate(-17665 -1456)">
                                <g id="Mask_Group_19911" data-name="Mask Group 19911" transform="translate(-1092 -18)"
                                   clip-path="url(#clip-path)">
                                    <g id="Base" transform="translate(1092 18)" fill="none" stroke="#f5f5f5"
                                       stroke-width="1">
                                        <circle cx="14" cy="14" r="14" stroke="none"/>
                                        <circle cx="14" cy="14" r="13.5" fill="none"/>
                                    </g>
                                    <circle id="Hover" cx="14" cy="14" r="14" transform="translate(1092 18)"
                                            fill="#f5f5f5"/>
                                    <path id="Path_1920" data-name="Path 1920"
                                          d="M10.542,0C8.961,0,7.655,1.47,7.126,2.16,6.6,1.47,5.292,0,3.711,0A3.98,3.98,0,0,0,0,4.2,4.383,4.383,0,0,0,1.378,7.438a.328.328,0,0,0,.047.061L6.917,12.98a.293.293,0,0,0,.209.086.288.288,0,0,0,.209-.086l5.676-5.673.058-.056c.047-.045.092-.089.145-.145a.307.307,0,0,0,.053-.07A4.475,4.475,0,0,0,14.253,4.2,3.981,3.981,0,0,0,10.542,0Zm2.23,6.7a.124.124,0,0,0-.022.031c-.028.031-.058.061-.089.089L7.126,12.348,1.912,7.143a.267.267,0,0,0-.07-.089A3.78,3.78,0,0,1,.593,4.192,3.388,3.388,0,0,1,3.711.593c1.7,0,3.151,2.216,3.165,2.238a.307.307,0,0,0,.5,0C7.388,2.809,8.838.593,10.539.593a3.388,3.388,0,0,1,3.118,3.6A3.855,3.855,0,0,1,12.772,6.7Z"
                                          transform="translate(1098.874 25.467)" fill="#212158" stroke="#212158"
                                          stroke-width="0.5"/>
                                </g>
                            </g>
                        </g>
                    </svg>


                </div>

            }


            <Link to={src || '/project/1'}/>
            <div className="project-single__img">
                <Img src={img} alt=""/>
                {coming || sold || preBook || sapphire ?
                    <div className="c-badge"
                         style={coming && {backgroundColor: '#F29F05'} || sold && {backgroundColor: '#E63C23'} || preBook && {backgroundColor: '#71A658'} || sapphire && {backgroundColor: '#212158'}}>
                        <p>{coming && 'Coming Soon' || sold && 'Sold Out' || preBook && 'Pre-booking' || sapphire && "Sapphire Series"}</p>
                    </div> : ''
                }
            </div>

            <div className="project-single__content">
                <h4>{reactHtmlParser(title)}</h4>
                <p>{category} {area && <span/>} {area} </p>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;

  .add-to-fav {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 6;

    svg {
      circle, path {
        transition: .6s ease;
      }
    }

    &:hover {
      svg {
        #Hover {
          cy: -13px;
        }

        #Path_1920 {
          stroke: ${text};
        }
      }
    }
  }

  a {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 5;
    top: 0;
    left: 0;
  }

  .project-single__img {
    padding-top: calc(520 / 370 * 100%);
    position: relative;
    overflow: hidden;

    .c-badge {
      width: 44px;
      height: 114px;
      position: absolute;
      left: 20px;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 12px;
        line-height: 16px;
        color: ${whites};
        transform: rotate(-90deg);
        white-space: nowrap;
        //transform-origin: left bottom;
      }
    }
  }

  .project-single__content {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 40px;
    width: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    transition: .4s ease;

    h4 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 300;
      color: #F5F5F5;
      margin-bottom: 2px;
    }

    p {
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 20px;
      font-weight: bold;
      color: #F5F5F5;

      span {
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background-color: #FFF;
        margin: 0 8px;
        display: inline-block;

      }
    }

  }

  &:hover {
    img {
      transform: scale(1.04);
    }

    .project-single__content {
      background-color: rgba(33, 33, 88, 0.9);
    }
  }
`;

export default MyComponent;
