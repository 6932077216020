import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Text from "../../components/career/Text";
import Jobs from "../../components/career/Jobs";
import Departments from "../../components/career/Departments";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchPosts} from "../../api/redux/career";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/animations/PageTransition";
import {whites} from "../../styles/globalStyleVars";

const MyComponent = () => {

    const dispatch = useDispatch()
    let getPost = useSelector(state => state.careerReducer)

    // api config
    const endPoints = apiEndPoints.SECTIONS
    const params = {
        [ApiParam.type]: 'slug',
        [ApiParam.value]: 'career',
        [ApiParam.get_section]: 'yes',
        [ApiParam.image]: 'yes',
        [ApiParam.post]: 'yes',
    }
    // api call
    useEffect(() => {
        dispatch(fetchPosts([endPoints, params]))
    }, [])


    // refactor data
    const department = getPost?.data?.data?.sections?.find(f => f.section_data?.slug === 'the-departments')
    const innerBannerTitle = getPost?.data?.data?.page_data?.short_desc;
    const innerBannerImage = getPost?.data?.data?.page_images?.list?.[0]?.full_path;


    return (
        <HelmetProvider>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Career | Sheltech Pvt Ltd</title>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={innerBannerTitle} subTitle={'Career'}
                             img={innerBannerImage}/>
                <Text/>
                <Jobs data={getPost && getPost?.data?.job_list}/>
                <Departments data={department}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
    //section {
    //  overflow: hidden;
    //  margin-bottom: -2px;
    //}

    .texts-section {
        background-color: ${whites};

        p, h1, h2, h4 {
            color: rgb(8, 8, 43);
        }
    }

    // .department {
        //   background-color: ${whites};
    //
    //   p, h1, h2, h4 {
    //     color: rgb(8, 8, 43);
    //   }
    //
    //   img {
    //     filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    //   }
    // }

`;

export default MyComponent;
