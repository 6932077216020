import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {text, whites} from "../../styles/globalStyleVars";
import Media from "../Media";
import Button from "../Button";
import Title from "../Title";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {PostForm} from "../../api/redux/landowner";

const MyComponent = ({data}) => {
    const store = useSelector(store => store)

    const [type, setType] = useState(0)
    const handleFilter = (e) => {
        setType(e)
    }

    // filter by on click
    const filteredData = type !== 0 ? data?.filter(e => e?.page_data?.type_id === type) : data

    // useEffect(() => {
    //     console.log('type', type)
    // }, [type])

    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    const apiEndPoint = apiEndPoints.POST_FORM


    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append('name', e?.name);
        formData.append('email', e?.email);

        dispatch(PostForm([apiEndPoint, formData]))
    }

    useEffect(() => {

        if (!store?.landReducer?.formLoading && isSubmitSuccessful) {
            success('Thank you for your message')
            reset()
        }
    }, [isSubmitSuccessful, store])


    return (
        <>
            <StyledComponent className={'media-list pt-120 pb-120'}>
                <Container>
                    <div className="media-list__top">
                        <Row>
                            <Col sm={12}>
                                <ul>
                                    <li className={type === 0 ? 'active' : ''} onClick={() => handleFilter(0)}>All</li>
                                    <li className={type === 3 ? 'active' : ''} onClick={() => handleFilter(3)}>News</li>
                                    <li className={type === 5 ? 'active' : ''} onClick={() => handleFilter(5)}>Events
                                    </li>
                                    <li className={type === 4 ? 'active' : ''} onClick={() => handleFilter(4)}>Blogs
                                    </li>
                                    <li className={type === 6 ? 'active' : ''}
                                        onClick={() => handleFilter(6)}>Newsletters
                                    </li>
                                </ul>
                            </Col>

                        </Row>
                    </div>
                    <Row>
                        {filteredData?.length > 0 && filteredData?.map(i => (
                            <Col key={i?.page_data?.id} xl={4} md={6} sm={6}>
                                {i?.page_data?.type === "Newsletters" ? (
                                    <Media
                                        category={i?.page_data?.type}
                                        link={i?.files?.list?.[0]?.full_path}
                                        date={i?.page_data?.short_desc}
                                        title={i?.page_data?.title}
                                        img={i?.images?.list?.[0]?.full_path}
                                    />
                                ) : (
                                    <Media
                                        category={i?.page_data?.type}
                                        link={i?.page_data?.slug}
                                        date={i?.page_data?.short_desc}
                                        title={i?.page_data?.title}
                                        img={i?.images?.list?.[0]?.full_path}
                                    />
                                )}
                            </Col>
                        ))}


                    </Row>

                </Container>

            </StyledComponent>


            {/*subscribe by email  */}
            {/*{type === 6 &&*/}
            <StyledSubscribe className={"pt-150 pb-150"}>
                <Container>
                    <Row>
                        <Col sm={{span: 8, offset: 2}} className={"news-letter"}>
                            <Title margin={'0 auto 60px auto'} color={'#FFF'} center
                                   text={'Subscribe to Our Newsletter'}/>
                            <Form>

                                <Form.Group className={'from-group'}>
                                    <Form.Control {...register('name', {
                                        required: {
                                            value: true,
                                            message: "Please enter your full name",
                                        }

                                    })} type="text" placeholder="Name*"/>
                                    <p className={'form-error'}>{errors.name?.message}</p>
                                </Form.Group>

                                <Form.Group className={'from-group'}>
                                    <Form.Control {...register('email', {
                                        required: {
                                            value: true,
                                            message: "Please enter a valid email address"
                                        },
                                        pattern: {
                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: "Please enter a valid email address"
                                        }
                                    })} type="email" placeholder="Email*"/>
                                    <p className={'form-error'}>{errors.email?.message}</p>
                                </Form.Group>

                                <div onClick={handleSubmit(handleFormSubmit)}>
                                    <Button margin={'60px auto 0 auto'} text={'Subscribe'}/>
                                </div>
                            </Form>
                        </Col>

                    </Row>
                </Container>
            </StyledSubscribe>
            {/*}*/}

        </>
    );
};

const StyledComponent = styled.section`
  .media-list__top {
    width: 100%;
    margin-bottom: 60px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        padding: 0px 24px;
        border: 1px solid ${text};
        border-radius: 100px;
        height: 33px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        transition: .5s ease;
        cursor: pointer;

        &:not(:nth-last-child(1)) {
          margin-right: 30px;
        }

        &:hover, &.active {
          background-color: ${text};
          color: #ffffff;
        }
      }
    }
  }

  .search {
    position: relative;

    input {
      width: 100%;
      border-radius: 100px;
      height: 33px;
      border: 1px solid ${text};
      box-shadow: none;
      outline: none;
      padding-left: 24px;
      padding-right: 60px;
      position: relative;

      &::placeholder {
        color: ${text};
      }
    }

    button {
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: transparent;
      box-shadow: none;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
    }

  }

  .media-single {
    margin-bottom: 60px;
  }

  @media (min-width: 1650px) {
    .col-md-6 {
      max-width: 25%;
    }
  }

  @media (max-width: 767px) {
    .media-list__top ul {
      margin-bottom: 30px;
    }

    .media-single {
      margin-bottom: 40px;
    }
  }
`;

const StyledSubscribe = styled.section`
  background-color: #212158;

  .from-group {
    &:nth-of-type(1) {
      margin-bottom: 30px;
    }
  }

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #F5F5F5;
    padding-left: 0;
    height: 45px;
    //margin-bottom: 25px;
    color: #F5F5F5;

    &::placeholder {
      font-size: 15px;
      color: ${whites};
    }
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: ${whites} !important;
    border-bottom: 1px solid #F5F5F5;

    &:hover {
      border-bottom: 1px solid #F5F5F5 !important;
    }

    &:focus {
      border-bottom: 1px solid #F5F5F5 !important;
    }
  }
`

export default MyComponent;
