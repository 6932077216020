import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {whites} from "../../styles/globalStyleVars";

const MyComponent = () => {
    return (
        <StyledComponent className={'texts-section pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col md={{span: 10, offset: 1}} sm={12} className={'text-center'}>
                        <img src="/images/static/flower.png" alt="" className="shadow-right"/>
                        <img src="/images/static/flower-v2.png" alt="" className="shadow-left"/>
                        <Title color={'#F5F5F5'} center text={'Empowering Growth & Nurturing Talent'}/>
                        <p>At Sheltech Pvt Ltd, we believe that our most valuable asset is our people. Our human
                            resources philosophy is rooted in the principles of growth, opportunity, and excellence. We
                            strive to create an environment that fosters professional development, encourages innovative
                            thinking, and values diversity.
                        </p>
                        <p>We recognize that the success of our company is directly linked to the personal and
                            professional success of our employees. Thus, we offer a multitude of opportunities for
                            learning and advancement, enabling each team member to reach their full potential.</p>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #000000;
  overflow: hidden;

  .container {
    position: relative;
  }

  .shadow-right {
    position: absolute;
    right: -50px;
    top: -50px;
  }

  .shadow-left {
    position: absolute;
    left: -150px;
    bottom: 10px;
  }

  p {
    color: ${whites};

    &:nth-last-child(1) {
      margin-top: 20px;
    }
  }

  @media (max-width: 991px) {
    .shadow-right {
      height: 80px;
    }

    .shadow-left {
      height: 80px;
    }
  }

  @media (max-width: 767px) {
    .shadow-right {
      height: 50px;
      right: 0;
    }

    .shadow-left {
      height: 40px;
      left: 0;
      bottom: -40px;
    }
  }
`;

export default MyComponent;
