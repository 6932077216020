import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {text, title} from "../../styles/globalStyleVars";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import {Img} from "../Img";

const MyComponent = ({data}) => {

    const [innerWidth, setInnerWidth] = useState(0)
    const [imageIndex, setImagerIndex] = useState(1)
    const handleIndex = (e) => {
        setImagerIndex(e + 1)
    }

    useEffect(() => {
        setInnerWidth(window.innerWidth)
        window.addEventListener('resize', () => {
            setInnerWidth(window.innerWidth)
        })
    }, [])


    // slider ---
    const swiperRef = useRef(null);
    const swiperRefTwo = useRef(null);

    const handleProgress = (swiper, event) => {
        var interleaveOffset = 0.8;
        for (let i = 0; i < swiper.slides.length; i++) {
            var slideProgress = swiper.slides[i].progress;
            var innerOffset = swiper.width * interleaveOffset;
            var innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".global-image").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
        }
    };

    // hand touch move not required this slider
    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };

    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".global-image")
                .style.transition = `${speed}ms`;
        }
    };

    // handle pagination
    var pagination_title = ['Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities', 'Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities']
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<div class="' + className + '"><span>' + pagination_title[index] + "</span></div>";
        },
    };
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const handleSlideChange = (event) => {
        const newActiveSlideIndex = event.realIndex;
        setActiveSlideIndex(newActiveSlideIndex);
        if (swiperRefTwo.current) {
            swiperRefTwo.current.swiper.slideTo(newActiveSlideIndex);
        }

        handleIndex(event.activeIndex)
    };

    const handleButtonClickPagination = (index) => {
        if (swiperRef.current) {
            setActiveSlideIndex(index);
            swiperRef.current.swiper.slideToLoop(index);
        }
    };


    const bannerData = data?.posts;


    return (
        <StyledComponent className={'banner-slider'} style={{height: "100svh"}}>

            <div className="banner-slider__init">
                {bannerData?.list.length > 0 &&
                    <Swiper key={'1'} ref={swiperRef}
                            loop={true}
                            spaceBetween={0}
                            slidesPerView={1}

                            allowTouchMove={true}
                            speed={1200}
                            onSlideChange={handleSlideChange}
                            onProgress={handleProgress}
                            touchStart={handleTouchStart}
                            onSetTransition={handleSetTransition}
                            watchSlidesProgress={true}
                            centeredSlides={false}
                        // mousewheelControl={true}
                        //     keyboardControl={true}
                            pagination={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}

                            modules={[Autoplay, Navigation]}
                        // autoplay={false}
                            autoplay={{
                                delay: 8000,
                                // disableOnInteraction: false,
                            }}
                            className="mySwiper main-swiper"
                    >

                        {bannerData?.list?.map(i => {
                            if (i?.images?.[0]?.ext === "mp4") {
                                return (
                                    <SwiperSlide key={i?.data?.id}>
                                        <Img src={'/images/dynamic/contact-banner.jpg'}/>
                                        <video style={{height: "100svh"}} loop autoPlay muted
                                               poster={i?.images?.find(f => f.background === "on")?.full_path}>
                                            <source src={i?.images?.[0]?.full_path} type="video/mp4"/>
                                        </video>

                                        <div className="banner-slider__item__content">
                                            {i?.data?.subtitle && <h4>{i?.data?.subtitle}</h4>}
                                            {i?.data?.title && <h1>{i?.data?.title}</h1>}
                                            {i?.data?.short_desc && <h4>{i?.data?.short_desc}</h4>}
                                        </div>
                                    </SwiperSlide>
                                )
                            } else {
                                return (
                                    <SwiperSlide key={i?.data?.id}>
                                        <div className="banner-slider__item" style={{height: "100svh"}}>
                                            <Img src={i?.images?.find(f => f?.desktop === "on")?.full_path}/>
                                            <div className="banner-slider__item__content">
                                                {i?.data?.subtitle && <h4>{i?.data?.subtitle}</h4>}
                                                {i?.data?.title && <h1>{i?.data?.title}</h1>}
                                                {i?.data?.short_desc && <h4>{i?.data?.short_desc}</h4>}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            }

                        })}
                    </Swiper>
                }
                <div className="swiper-button-prev"/>
                <div className="swiper-button-next"/>
            </div>


            {/*<video loop autoPlay muted poster={'/images/dyanmic/home-banner.jpg'}>*/}
            {/*    <source src={'/videos/sheltech_video.mp4'} type="video/mp4"/>*/}
            {/*</video>*/}


            <div className="banner-slider__menu">
                <ul>
                    <li>
                        <div className="banner-slider__menu__inner">
                            <Link to={'/projects?type=residential'}>
                                <img alt={'residential'} src={'/images/static/home-icon.svg'}/><span>Residential</span>
                            </Link>
                        </div>
                    </li>
                    <li>
                        <div className="banner-slider__menu__inner">
                            <Link to={'/projects?type=commercial'}>
                                <img alt={'commercial'}
                                     src={'/images/static/building-icon.svg'}/><span>Commercial</span>
                            </Link>
                        </div>

                    </li>
                    <li>
                        <div className="banner-slider__menu__inner">
                            <Link to={'/landowner'}>
                                <img alt={'landowner'} src={'/images/static/location-icon.svg'}/><span>Landowner</span>
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    //height: 100vh;
    position: relative;
    background-color: #000;

    video {
        position: absolute;
        //height: 100vh;
        left: 0;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        z-index: 5;
    }

    .swiper {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 5;
        overflow: hidden;
    }

    .swiper-slide {
        overflow: hidden;

        //&:nth-of-type(1) {
        //  .global-image {
        //    display: none;
        //  }
        //}
    }

    .banner-slider__init {
        //position: relative;
        //height: 100vh;
        //width: 100%;
        //background-color: red;

        .banner-slider__item {
            //height: 100vh;
            position: relative;
            width: 100%;

            &__content {
                text-align: center;
                position: absolute;
                z-index: 8;
                width: fit-content;
                height: fit-content;
                margin: auto;
                inset: 0;
                max-width: calc(100% - 30px);

                h4 {
                    font-size: 45px;
                    line-height: 32px;
                    font-family: ${title};
                    text-transform: uppercase;
                    color: rgb(255, 255, 255);
                    -webkit-background-clip: text !important;
                    opacity: 0;
                    transform: translateY(15px);
                    transition-delay: 0s;
                    transition: all .8s ease;

                    &:nth-of-type(1) {
                        margin-bottom: 20px;
                    }

                }

                h1 {
                    font-size: 90px;
                    line-height: 90px;
                    font-family: ${title};
                    text-transform: uppercase;
                    margin-bottom: 15px;
                    color: rgba(255, 255, 255, 0.75);
                    -webkit-background-clip: text !important;
                    opacity: 0;
                    transition: all .8s ease;
                    transition-delay: 0s;
                    transform: translateY(15px);
                }
            }
        }
    }


    .banner-slider__content {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;

    }

    .banner-slider__menu {
        position: absolute;
        bottom: 0;
        z-index: 70;
        width: 100%;

        ul {
            display: flex;
            width: 50%;
            margin: auto;

            li {
                margin-right: 1px;
                width: 33.33%;
                position: relative;

                img {
                    transition: .4s ease;
                }

                &:before {
                    content: '';
                    position: absolute;
                    height: 2px;
                    top: 0;
                    width: 0%;
                    left: 0;
                    background-color: #fff;
                    transition: all 150ms ease;
                    transition-delay: 450ms;
                    z-index: 3;
                }

                &:after {
                    content: '';
                    position: absolute;
                    height: 0%;
                    top: 0;
                    width: 2px;
                    right: 0;
                    background-color: #fff;
                    transition: all 150ms linear;
                    transition-delay: 300ms;
                    z-index: 3;
                }

                .banner-slider__menu__inner {
                    padding-top: calc(110 / 190 * 100%);
                    position: relative;
                    transition: .6s ease;

                    &:before {
                        content: '';
                        position: absolute;
                        height: 2px;
                        bottom: 0;
                        width: 0%;
                        right: 0;
                        background-color: #fff;
                        transition: all 150ms ease;
                        transition-delay: 150ms;
                        z-index: 3;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        height: 0%;
                        bottom: 0;
                        width: 2px;
                        left: 0;
                        background-color: #fff;
                        transition: all 150ms ease;
                        transition-delay: 0s;
                        z-index: 3;
                    }


                    a {
                        position: absolute;
                        color: #F5F5F5 !important;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        width: 100%;
                        backdrop-filter: blur(10px);
                        background-color: rgba(255, 255, 255, 0.14);
                        transition: all .5s ease;
                        font-size: 17px;

                        img {
                            margin-bottom: 5px;
                            height: 47px;
                        }

                        &:hover {
                            background-color: rgba(133, 131, 131, 0.2);
                        }
                    }

                }

                //hover

                &:hover {
                    &:before {
                        width: 100%;
                        transition-delay: 0s;
                    }

                    &:after {
                        height: 100%;
                        transition-delay: 150ms;
                    }

                    .banner-slider__menu__inner {
                        background-color: rgb(255, 255, 255);
                        transition-delay: .2s;

                        img {
                            filter: invert(100%) sepia(5%) saturate(21%) hue-rotate(64deg) brightness(104%) contrast(104%);
                        }

                        a {
                            color: ${text} !important;
                        }

                        &:before {
                            width: 100%;
                            transition-delay: 300ms;
                        }

                        &:after {
                            height: 100%;
                            transition-delay: 450ms;
                        }
                    }
                }

            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 30%;
        height: 100%;
        top: 0;
        margin: 0;
        transition: cursor 0.3s;

        &::after {
            display: none;
        }
    }

    .swiper-button-prev {
        left: 0;
        cursor: url('images/static/slider-left.svg') 32 32,
        auto;
    }

    .swiper-button-next {
        right: 0;
        cursor: url('/images/static/slider-right.svg') 32 32,
        auto;
    }

    .swiper-slide-active {
        .banner-slider__item__content {
            h4 {
                opacity: .8;
                transition-delay: 1.3s;
                transform: none;

                &:nth-last-of-type(1) {
                    transition-delay: 1.5s;
                }
            }

            h1 {
                opacity: .9;
                transform: none;
                transition-delay: 1.4s;
            }
        }
    }

    @media (max-width: 991px) {
        .banner-slider__content__inner {
            h4 {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 9px !important;
            }

            h1 {
                font-size: 60px;
                line-height: 60px;
                margin-bottom: 9px;
            }
        }

        .banner-slider__menu {
            ul {
                width: 80%;
            }
        }
    }

    @media (max-width: 768px) {
        .banner-slider__init .banner-slider__item__content {
            h1 {
                font-size: 40px;
                line-height: 45px;
            }

            h4 {
                font-size: 30px;
                line-height: 35px;
            }

        }
    }

    @media (max-width: 767px) {
        .banner-slider__menu ul {
            width: calc(100% - 30px);

            li {
                &:not(:nth-last-of-type(1)) {
                    margin-right: 5px;
                }

                .banner-slider__menu__inner {
                    padding-top: calc(102 / 111 * 100%);
                }

            }

            .banner-slider__menu__inner {
                img {
                    height: 35px !important;
                }

                a {
                    font-size: 14px !important;
                }
            }
        }

    }

`;

export default MyComponent;
