import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { gsap } from "gsap";

const MyComponent = ({ data }) => {
  useEffect(() => {
    let tl = gsap.timeline();
    let tl2 = gsap.timeline();
    tl.to(".promotional-popup", {
      duration: 0,
      display: "flex",
      delay: 3,
    })
      .to(".promotional-popup", {
        opacity: 1,
      })
      .to(".popup-close", {
        duration: 0.3,
        opacity: 1,
      })
      .to(".promotional-popup", {
        opacity: 0,
        delay: 10,
        duration: 0.4,
      })
      .to(".promotional-popup", {
        display: "none",
        duration: 0,
      })
      .to(".popup-close", {
        duration: 0,
        opacity: 0,
      });

    document
      .querySelector(".popup-close")
      .addEventListener("click", function () {
        tl2
          .to(".promotional-popup", {
            opacity: 0,
            duration: 0.4,
          })
          .to(".promotional-popup", {
            display: "none",
            duration: 0,
          })
          .to(".popup-close", {
            duration: 0,
            opacity: 0,
          });
      });
  }, []);

  return (
    <StyledComponent className={"promotional-popup"}>
      <div className="promotional-popup__img">
        <img src={data?.images?.list?.[0]?.full_path} alt="" />
        {data?.images?.list?.[0]?.short_desc && (
          <a href={data?.images?.list?.[0]?.short_desc} />
        )}

        <img
          className={"popup-close"}
          src="/images/static/popup-close.svg"
          alt=""
        />
      </div>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  align-items: center;
  display: none;
  opacity: 0;

  .promotional-popup__img {
    width: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    //height: fit-content;
    position: relative;
    justify-content: center;
    height: 80%;

    .popup-close {
      position: absolute;
      top: 5px;
      //right: 5px;
      z-index: 2;
      cursor: pointer;
      opacity: 0;
      left: 0;
      right: 0;
      margin: auto;
      filter: invert(130%) sepia(0%) saturate(5%) hue-rotate(81deg)
        brightness(97%) contrast(108%);
    }

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    img {
      max-width: 65%;
      object-fit: contain;
    }
  }

  @media (max-width: 767px) {
    .promotional-popup__img {
      width: 90%;

      img:not(.popup-close) {
        min-width: 90%;
      }
    }
  }
`;

export default MyComponent;
