import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {whites} from "../../styles/globalStyleVars";
import {Img} from "../Img";

import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import SliderNextWhite from "../SliderNextWhite";
import SliderPrevWhite from "../SliderPrevWhite";

const MyComponent = ({data, list}) => {


    return (
        <StyledComponent className={'csr pt-150 pb-200'}>
            <Container>

                <div className="csr__title">
                    <Title margin={'0 0 80px 0'} text={data?.section_data?.subtitle}/>
                    <ul>
                        <li className={'slider_prev'}><SliderPrevWhite color={'#08082B'} borderColor={'#08082B'}
                                                                       hoverBackground={'#08082B'}
                                                                       hoverColor={'#FFF'}/></li>
                        <li className={'slider_next'}><SliderNextWhite color={'#08082B'} borderColor={'#08082B'}
                                                                       hoverBackground={'#08082B'}
                                                                       hoverColor={'#FFF'}/></li>

                    </ul>
                </div>

                {list && list?.length > 0 &&
                    <Swiper loop={false}
                            spaceBetween={30}
                            slidesPerView={1}
                            allowSlideNext={true}
                            allowSlidePrev={true}
                        // allowTouchMove={false}
                            speed={900}
                        // pagination={{
                        //     type: "fraction",
                        // }}
                            navigation={{
                                prevEl: '.csr .slider_prev',
                                nextEl: '.csr .slider_next ',
                            }}
                            modules={[Autoplay, Pagination, Navigation]}

                        // autoplay={false}

                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                1600: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                                600: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                500: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },

                            }}>

                        {list && list?.length > 0 && list?.map(i => (
                            <SwiperSlide key={i?.page_data?.id}>
                                <div className="csr__slider">
                                    <Link to={`/csr/${i?.page_data?.slug}`}/>
                                    <div className="csr__slider__img">
                                        <Img src={i?.images?.list?.find(f => f?.thumb === 'on')?.full_path}/>
                                    </div>
                                    <div className="csr__slider__content">
                                        <p>{i?.page_data?.title}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}


                    </Swiper>
                }

            </Container>


        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: rgb(214, 214, 214);

  .csr__title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    ul {
      display: flex;
    }
  }

  .csr__slider {
    position: relative;

    a {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    &__img {
      padding-top: calc(520 / 370 * 100%);
      position: relative;
      overflow: hidden;
    }

    &__content {
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px solid rgba(8, 8, 43, 0.2);

      p {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &:hover {
      img {
        transform: scale(1.04);
      }
    }
  }

  @media (max-width: 767px) {
    ul {
      width: 100%;
      margin-bottom: 40px;
    }
  }
`;

export default MyComponent;
