import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Title from "../../components/Title";
import {whites} from "../../styles/globalStyleVars";
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "../../components/Button";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/animations/PageTransition";


const MyComponent = () => {
    return (
        <HelmetProvider>
            {/*<motion.div key={`1823aa`} className="page-loader" exit="exit" animate="anim"*/}
            {/*            style={{height: `${document.body.clientHeight}px`}}*/}
            {/*            variants={PageAnimation}*/}
            {/*            initial="init">*/}
            {/*</motion.div>*/}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Sign Up | Sheltech Pvt Ltd</title>
            </Helmet>

            <StyledComponent className={'user pt-200 pb-200'}>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <Title margin={'0 0 60px 0'} color={whites} text={'Reset Password'}/>
                        </Col>

                        <Col md={6} sm={12}>
                            <Form>

                                <Form.Group className={'from-group'}>
                                    <Form.Control type="email*" placeholder="Email*"/>
                                </Form.Group>

                                <Button submitButton margin={'60px 0 0 0'} text={'Submit'}/>
                            </Form>


                        </Col>
                    </Row>

                </Container>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  background-color: #08082B;

  //section {
  //  overflow: hidden;
  //  margin-bottom: -2px;
  //}

  .from-group {
    margin-bottom: 25px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #F5F5F5;
    padding-left: 0;
    height: 45px;
    //margin-bottom: 25px;
    color: #F5F5F5;

    &::placeholder {
      font-size: 15px;
      color: ${whites};
    }
  }

  .user-alter {
    margin-top: 60px;

    p {
      color: ${whites};

      &:nth-of-type(1) {
        margin-bottom: 20px;
      }
    }

    a {
      color: #ec1c24;
      text-decoration: underline;

      &:hover {
        color: ${whites} !important;
      }
    }
  }

`;

export default MyComponent;
