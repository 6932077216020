import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {title, whites} from "../../styles/globalStyleVars";
import {Img} from "../Img";

const MyComponent = ({data, id, progress}) => {

    return (
        <StyledComponent id={id} progress={progress} className={'timeline'}>
            <Img src={'/images/static/timeline-bg.png'}/>

            <Container>
                <Row>
                    <Col sm={12} md={6}>
                        <h3>Project <br/> Progress</h3>
                    </Col>

                    <Col sm={12} md={6}>
                        <h4><span>Project Start</span> <span>Completed</span></h4>
                        <div className="timeline__progress">
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding: 60px 0;
  background-color: #212158;
  position: relative;

  h3 {
    font-size: 36px;
    line-height: 44px;
    font-family: ${title};
    color: ${whites};
    text-transform: uppercase;
  }

  h4 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
    color: ${whites};
    font-size: 15px;
    line-height: 20px;
  }

  .timeline__progress {
    width: 100%;
    height: 1px;
    background-color: #F5F5F5;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 6px;
      background-color: #F5F5F5;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      width: ${p => p.progress};
    }

    span {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #F5F5F5;
      position: absolute;
      top: 0;
      bottom: 0px;
      margin: auto;

      &:after {
        content: '';
        font-size: 15px;
        line-height: 20px;
        color: ${whites};
        bottom: -25px;
        position: absolute;
        right: 0;
      }

      &:nth-of-type(1) {
        left: -1px;

        &:after {
          content: '0%';
        }

      }

      &:nth-last-child(2) {
        left: calc(${p => p.progress} - 20px);

        &:after {
          content: "${p => p.progress}";
          top: -25px;
          bottom: auto;
        }

        animation: pulse 2s ease-in infinite;

      }

      &:nth-last-child(1) {
        right: 0;

        &:after {
          content: '100%';
          right: 0;
        }
      }
    }
  }

  @keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(180, 112, 79, 0.62), 0 0 0 0 rgba(255, 109, 74, .7);
      box-shadow: 0 0 0 0 rgba(222, 220, 219, 0.71), 0 0 0 0 rgba(255, 109, 74, .7)
    }

    40% {
      -webkit-box-shadow: 0 0 0 30px rgba(255, 110, 74, .466), 0 0 0 0 rgba(255, 109, 74, .7);
      box-shadow: 0 0 0 30px transparent, 0 0 0 0 rgba(255, 109, 74, .7)
    }

    80% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 110, 74, .555), 0 0 0 0 transparent;
      box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent
    }

    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 96, 96, .698), 0 0 0 0 rgba(190, 109, 109, .75);
      box-shadow: 0 0 0 0 rgba(202, 123, 123, .75), 0 0 0 0 rgba(199, 124, 124, .75)
    }
  }

  @media (max-width: 767px) {
    h3 {
      margin-bottom: 40px;
      font-size: 28px;
      line-height: 36px;
    }

    img {
      object-position: right;
    }

    padding-top: 80px;
    padding-bottom: 100px;
  }
`;

export default MyComponent;
