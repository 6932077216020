import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {ease, whites} from "../styles/globalStyleVars";

const MyComponent = () => {
    return (
        <StyledComponent className={'close-button'}>
            <svg className={'initial-close'} xmlns="http://www.w3.org/2000/svg" width="11.414" height="11.414"
                 viewBox="0 0 11.414 11.414">
                <g id="Group_23146" data-name="Group 23146" transform="translate(-1077.294 -689.293)">
                    <line id="Line_12415" data-name="Line 12415" x1="14.142"
                          transform="translate(1078.001 690) rotate(45)" fill="none" stroke="#f5f5f5"
                          stroke-linecap="round" stroke-width="1"/>
                    <line id="Line_12416" data-name="Line 12416" x1="14.142"
                          transform="translate(1078.001 700) rotate(-45)" fill="none" stroke="#f5f5f5"
                          stroke-linecap="round" stroke-width="1"/>
                </g>
            </svg>

            <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="11.414" viewBox="0 0 11.414 11.414">
  <g id="Group_23147" data-name="Group 23147" transform="translate(-1077.294 -689.293)">
    <line id="Line_12415" data-name="Line 12415" x1="14.142" transform="translate(1078.001 690) rotate(45)" fill="none"
          stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
    <line id="Line_12416" data-name="Line 12416" x1="14.142" transform="translate(1078.001 700) rotate(-45)" fill="none"
          stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
  </g>
</svg>

            </span>

        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #FFF;
  right: 30px;
  top: 30px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: all .6s ${ease};
  }

  span {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: ${whites};
    left: 0;
    bottom: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(40px);
    transition: all .6s ${ease};
  }

  &:hover {
    .initial-close {
      transform: translateY(-40px);
    }

    span {
      transform: none;
    }
  }

  @media (max-width: 767px) {
    top: 20px;
    right: 15px;
  }
`;

export default MyComponent;
