import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "./Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({img, title, deg, handlePopup, description, fb, linkedIn, instagram, x, youtube}) => {
    return (
        <StyledComponent onClick={() => handlePopup && handlePopup({
            title,
            img,
            deg,
            description,
            fb,
            linkedIn,
            instagram,
            x,
            youtube
        })} className={'team-single'}>
            <div className="team-single__img">
                <Img src={img}/>
            </div>
            <div className="team-single__content">
                <h4>{reactHtmlParser(title)}</h4>
                <p>{reactHtmlParser(deg)}</p>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  //margin-bottom: 80px;
  position: relative;
  cursor: pointer;

  .team-single__img {
    position: relative;
    padding-top: calc(370 / 270 * 100%);
    overflow: hidden;
  }

  .team-single__content {
    padding-top: 20px;
    border-top: 1px solid rgba(8, 8, 43, 0.2);
    margin-top: 20px;

    h4 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      //margin-bottom: 2px;
    }

    p {
      color: rgba(8, 8, 43, 0.5);
    }
  }

  &:hover {
    .team-single__img img {
      transform: scale(1.04);
    }
  }
`;

export default MyComponent;
